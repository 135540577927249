import React from 'react'
import HorizontalBox from './HorizontalBox'
import { Typography } from '@mui/material'
import { colors } from '../../utils/colors'

const HorizontalHeadingPlusText = ({ heading, text }) => {
    return (
        <HorizontalBox>
            <Typography sx={{ fontWeight: 'bold', fontSize: 14, color: colors?.themeGreen }}>{heading}:</Typography>
            <Typography sx={{ ml: 1, fontSize: 14, textWrap: 'pretty' }}>{text}</Typography>
        </HorizontalBox>
    )
}

export default HorizontalHeadingPlusText