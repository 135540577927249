import axios from "axios";
import toast from "react-hot-toast";
const API_BASE_URL = process.env.REACT_APP_API_URL;


export const npiSync = async (requestData) => {
    try {
      const res = await axios.post(
        `${API_BASE_URL}/api/Npi/NpiFinderInquiry`, requestData,
        {
          headers: {
            "Content-Type": "application/json"
          },
        }
      );
      return res;
    } catch (err) {
      toast?.error(err?.response?.data?.detail ?? 'Failed to find npi details')
    }
  };