import React from 'react'
import { Box} from '@mui/material';
import MyTabs from '../../components/tabs/MyTabs'
import EligibilitySearchresultdata from '../Eligibility/EligibilitySearchresultdata';
import { useLocation } from 'react-router-dom';
import EligibilityClaimResultdata from './EligibilityClaimResultdata';


const EligibilitySearchResults = () => {
    const location = useLocation();
    const params = new URLSearchParams(location?.search);
    const throughEligibility = params.get('through') === 'Eligibility'

    const tabs =
    throughEligibility ?  [

            {
                label: "Results", value: <EligibilitySearchresultdata />, changeTab: 0, button: 'Back', goBack: true
            },


        ]
        :
        [

            {
                label: "Results", value: <EligibilityClaimResultdata />, changeTab: 0, button: 'Back', goBack: true
            },


        ]


    return (
        <section className='discovery'>
            <Box sx={{
                boxShadow: '0 0 2px 0 #919EAB',
                borderRadius: '12px'
            }}>
                <MyTabs tabs={tabs} />
            </Box>
        </section>
    )


}

export default EligibilitySearchResults