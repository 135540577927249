import React, { useEffect, useState } from 'react';
import HorizontalHeadingPlusText from '../shared/HorizontalHeadingPlusText';
import { update_UpdatePatientInsuranceDetails } from '../../services/registration';
import { Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import styles from "../../css/discoveryDetails.module.css";
import { formatPatientCoverageData, getTabNameLabel } from '../../Pages/Discovery/DiscoveryFunctions';
import SyncButton from './SyncButton';

const CoverageTab = ({ data, patientInsuranceTabData, tabname, patientId }) => {
  const { leftBox, header, rightBox, buttonGrid } = styles; 
  const coverageData = [
    { name: 'In Net HBPC Authorization Info', value: data?.inNetHBPCAuthorizationInfo },
    { name: 'Primary Care Provider Name', value: data?.primaryCareProviderName || 'N/A' },
    { name: 'Primary Care Provider Phone Number', value: data?.primaryCareProviderPhoneNumber || 'N/A' },
    { name: 'Out Net HBPC Authorization Info', value: data?.outNetHBPCAuthorizationInfo || 'N/A' },
  ];

  const [patientInsuranceDetailTabData, setPatientInsuranceDetailTabData] = useState([]); 
  const [isLoading, setIsLoading] = useState(false)

  useEffect(()=>{
    setPatientInsuranceDetailTabData(formatPatientCoverageData(patientInsuranceTabData))
  },[patientInsuranceTabData])

  const handleSavePatientsDetails = async (tabName, patientId) => {
    try {
      setIsLoading(true)
      const patientUpdatedData = await update_UpdatePatientInsuranceDetails(tabName, patientId);
      if (patientUpdatedData?.patientData?.coverageDetails) {
        setPatientInsuranceDetailTabData(formatPatientCoverageData(patientUpdatedData?.patientData?.coverageDetails));
      } else {
        toast('Coverage detail is empty');
      }
    } catch (error) {
      console.error(error);
    }
    finally{
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
        
          <Paper elevation={10} className={leftBox}>
            <Typography variant="h5" align="center" className={header}>
              Payer
            </Typography>
            <Typography align="center" variant="h5" className={header}>
            {getTabNameLabel(tabname)}
            </Typography>
            <hr/>
            {coverageData?.length && coverageData?.map((item, index) => (
               <HorizontalHeadingPlusText heading={item?.name} text={item?.value} key={index} />
            ))}
          </Paper>
        </Grid>

        {/* Button */}
        <Grid item xs={12} md={2} className={buttonGrid}>
          <SyncButton onClick={()=>handleSavePatientsDetails(tabname, patientId)} isLoading={isLoading}/> 
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={rightBox}>
            <Typography variant="h5" align="center" className={header}>
              Practice
            </Typography>
            <Typography align="center" variant="h5" className={header}>
            {getTabNameLabel(tabname)}
            </Typography>
            <hr />
            {patientInsuranceDetailTabData?.length &&
            patientInsuranceDetailTabData?.map(({heading,text},index)=>{
              return(
                <HorizontalHeadingPlusText heading={heading} text={text|| 'N/A'} key={index} /> 
              )
            })
            }
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default CoverageTab;
