import axios from "axios";
import toast from "react-hot-toast";
import { API_BASE_URL } from "../utils/staticData";

export const updateUserDetails = async (requestData) => {
    try {
        const res = await axios
            .post(API_BASE_URL + `/api/UserRegistration/UpdateUser`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
        return res
    } catch (error) {
        toast?.error(error?.response?.data?.detail ?? 'Failed to get User')
        console.log(error)
    }
}