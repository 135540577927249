import React from 'react'
import { Box, } from '@mui/material';
import MyTabs from '../../components/tabs/MyTabs'
import Searchresultdata from '../Discovery/Searchresultdata';


const Searchresults = () => {
    const tabs =
        [
            {
                label: "Results", value: <Searchresultdata />, changeTab: 0, button: 'Back',  backPath: '/discoveryTab?preSelectedTab=2'
            },
        ]
    return (
        <section className='discovery'>
            <Box sx={{
                boxShadow: '0 0 2px 0 #919EAB',
                borderRadius: '12px'
            }}>
                <MyTabs tabs={tabs} />
            </Box>
        </section>
    )
}
export default Searchresults