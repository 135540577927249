import { Box } from '@mui/material'
import React from 'react'

const ResponsiveHorizontalBox = ({ children,rowFromLg,itemsCenter }) => {
    return (
        <Box display={'flex'} justifyContent={'space-between'} 
        alignItems={
            itemsCenter ? 
            { xs: 'center', lg: 'stretch' }
            :
            { xs: 'stretch' }
        }
            flexDirection={
                rowFromLg ? 
                { xs: 'column', lg: 'row' }
                :
                { xs: 'column', md: 'row' }
            }

        >
            {children}
        </Box>
    )
}

export default ResponsiveHorizontalBox