import React, { useState } from 'react'
import MyTabs from '../../components/tabs/MyTabs'
import { Box } from '@mui/material'
import Eligibility from './Eligibility';
import Search from './Search';
import DentalSearch from './DentalSearch';
import { useSearchParams } from 'react-router-dom'
import { sharedStyles } from '../../sharedStyles';

const EligibilityTab = () => {
    const [searchParams] = useSearchParams();
    const preSelectedTabParam = searchParams.get('preSelectedTab');
    const [preSelectedTab] = useState(preSelectedTabParam ?? null)
     
    const tabs =
    [
        {
            label: "Eligibility ", value: <Eligibility />,
            button: 'New Search', changeTab: 1
        },
        {
            label: "Search", value: <Search />, changeTab: 1, button: 'Back',
        },
    ]

    return (
        <section className='Eligibility'>
            <Box sx={sharedStyles?.tabWrapper}>
                <MyTabs tabs={tabs} preSelectedTab={Number(preSelectedTab)} />
            </Box>
        </section>
    )
}
export default EligibilityTab
