import { colors } from "../../utils/colors";

export const styles = {
    modalTitle: { textAlign: 'center', fontWeight: 'bold', color: colors?.themeDarkBlue },
    modalBody: { mt: 2, textAlign: 'center', fontSize: '14px' },
    modalWrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: `2px solid ${colors?.black}`,
        boxShadow: 24,
        p: 4,
    },
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '1em',
        marginTop: '1.5em',
        justifyContent: 'center'
    }
}