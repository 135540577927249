import { Box, Typography } from '@mui/material'
import React from 'react'
import PatientsOverviewChart from './PatientsOverviewChart'
import { selectedCardGraphData, selectedCardHeading } from './dataFormatters'
import SelectedCardChart from './SelectedCardChart'

const PatientOverviewGraphs = ({selectedCard,isLoading,patientsOverviewData}) => {
  return (
    <Box
    boxShadow={3}
    height={'100%'}
    justifyContent={'center'}
    alignItems={'center'}
    display={'flex'}
    borderRadius={4}
    padding={2}
    flexDirection={'column'}
>
    {selectedCard &&
    <Typography>
        {selectedCardHeading(selectedCard)}
    </Typography>
    }
    {selectedCard ? 
    <SelectedCardChart data={selectedCardGraphData(selectedCard,patientsOverviewData)}/>
   
     :
    <PatientsOverviewChart  isLoading={isLoading} 
        patientsOverviewData={patientsOverviewData}
    />}
</Box>
  )
}

export default PatientOverviewGraphs