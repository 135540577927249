import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { get_GetAllClaimsStatusData } from '../services/ClaimService';
import toast from "react-hot-toast";
import { capitalizedFirstLetter, getLastElementFromArray } from '../utils/reusableFunctions';
import { filterRowData } from '../Pages/Claims/ClaimFunctions';
import PageLoader from '../components/loader/PageLoader'

const GridComponentClaims = ({ pagename, searchData, selectedUser }) => {
    const [rowData, setRowData] = useState([]);
    const [rowDataToUse, setRowDataToUse] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    let navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (pagename === "Claim") {
                    setIsLoading(true)
                    const data = await get_GetAllClaimsStatusData();
                    if (data?.claimsStatusSimplifiedDtos) {
                        const { claimsStatusSimplifiedDtos } = data
                        const flattenedRows = claimsStatusSimplifiedDtos?.map(({ patientInfoData: { id: ID, dob: DOB, firstName, lastName, phone: Phone, patientInsuranceDetails: [Insurance] }, category, claimStatuses }) => {
                            return {
                                ID,
                                Patient: capitalizedFirstLetter(firstName) + " " + capitalizedFirstLetter(lastName),
                                Phone,
                                DOB,
                                Insurance,
                                Status: category,
                                Date: claimStatuses?.length ? getLastElementFromArray(getLastElementFromArray(claimStatuses)?.dates)?.value : ''
                            }
                        })
                        setRowData(flattenedRows)
                    }
                }
                else {
                    const data = searchData;
                    const flattenedRows1 = [];
                    try {
                        data.forEach(vendorData => {
                            const vendor = vendorData?.vendor || 'N/A';
                            const claimStatusRoot = vendorData?.data?.claimStatusRoot;
                            const VendorId = vendorData?.vendor_id || 'N/A';
                            const dob = vendorData?.data?.patientData?.dob || 'N/A';
                            const patientId = claimStatusRoot?.patientId || 'N/A';
                            const patientName = `${capitalizedFirstLetter(claimStatusRoot?.subscriber?.firstName || '')} ${capitalizedFirstLetter(claimStatusRoot?.subscriber?.name || '')}`.trim();
                            const insurance = claimStatusRoot?.providerInfo?.name || 'N/A';
                            const phone = claimStatusRoot?.payerClaimNo || 'N/A';

                            if (claimStatusRoot?.claimStatuses?.length > 0) {
                                claimStatusRoot.claimStatuses.forEach(claimStatus => {
                                    const flattenedDataObj = {
                                        ID: patientId,
                                        Patient: patientName,
                                        VendorId: VendorId,
                                        Phone: phone,
                                        DOB: dob,
                                        Insurance: insurance,
                                        Vendor: vendor,
                                        Status: 'N/A',
                                        Date: 'N/A',
                                        effectiveDate: 'N/A'
                                    };
                                    const date = claimStatus?.dates?.[0]?.value || 'N/A';
                                    flattenedDataObj.Date = date;
                                    const status = claimStatus?.statusInfo?.statuses?.[0];
                                    if (status) {
                                        flattenedDataObj.Status = status?.category || 'N/A';
                                        flattenedDataObj.effectiveDate = status?.effectiveDate || 'N/A';
                                    }
                                    flattenedRows1.push(flattenedDataObj);
                                });
                            } else {
                                const fallbackDataObj = {
                                    ID: patientId,
                                    Patient: patientName,
                                    Phone: phone,
                                    DOB: dob,
                                    Insurance: insurance,
                                    Date: 'N/A',
                                    Status: 'N/A',
                                    effectiveDate: 'N/A',
                                    Vendor: vendor
                                };
                                flattenedRows1.push(fallbackDataObj);
                            }
                        });
                    } catch (error) {
                        
                        toast.error("Error No Response");
                    }
                    setRowData(flattenedRows1);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            finally {
                setIsLoading(false)
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedUser) {
            filterRowData(setRowDataToUse, rowData, selectedUser)
        }
        else setRowDataToUse(rowData)
    }, [rowData, selectedUser])

    const statusCellRenderer = (params) => {
        const statusValue = params.value;
        const statusStyle = {
            backgroundColor: '#F8FFF6',
            color: '#229A16',
        };
        return <span style={statusStyle}>{statusValue}</span>;
    };

    const DetailsButton = ({ data }) => {
        const handleClick = () => {
            navigate({
                pathname: '/Claimdetails1',
                search: `?id=${encodeURIComponent(data?.ID)}${pagename !== 'Claim' ? '&searchResult=true' : ''}`,
            });

        };
        return (
            <Button className='primary' style={{ backgroundColor: 'green', border: 'none', color: 'white', fontSize: 'small' }} onClick={handleClick} >
                View Details
            </Button>
        );
    };
    const columnDefs = () => {
        if (pagename === "Claim") {
            return [
                { headerName: 'ID#', field: 'ID', width: 120 },
                { headerName: 'Date', field: 'Date', sortable: true, filter: true },
                { headerName: 'Patient', field: 'Patient', filter: true },
                { headerName: 'Vendor', field: 'Vendor', filter: true, width: 236 },
                { headerName: 'Phone', field: 'Phone', filter: true },
                { headerName: 'Date of Birth', field: 'DOB', width: 120 },
                { headerName: 'Insurance Carrier', field: 'Insurance', width: 120, filter: true },
                { headerName: 'Status', field: 'Status', cellRenderer: statusCellRenderer, width: 280, filter: true },
                { headerName: 'Details', field: 'Details', width: 150, filter: true, cellRenderer: DetailsButton, },
            ];
        }
        else {
            return [
                { headerName: 'Date', field: 'Date', sortable: true, filter: true, width: 201 },
                { headerName: 'Patient', field: 'Patient', filter: true, width: 236 },
                { headerName: 'Vendor', field: 'Vendor', filter: true, width: 236 },
                { headerName: 'Phone', field: 'Phone', filter: true, width: 123 },
                { headerName: 'Date of Birth', field: 'DOB', width: 120 },
                { headerName: 'Insurance Carrier', field: 'Insurance', width: 165, filter: true },
                { headerName: 'Status', field: 'Status', cellRenderer: statusCellRenderer, filter: true, width: 226 },
                { headerName: 'Details', field: 'Details', width: 120, filter: true, cellRenderer: DetailsButton, flex: 1, minWidth: 143 },
            ];
        }
    };

    const gridOptions = {
        pagination: true,
        paginationPageSize: 10,
        domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
    };

    return (
        <div className="ag-theme-quartz"
            style={{ height: 400 }}  >
            {
                isLoading ? <PageLoader /> :
                    <AgGridReact
                        columnDefs={columnDefs()}
                        rowData={pagename !== "Claim" ? rowData : rowDataToUse}
                        gridOptions={pagename !== 'Claim' ? gridOptions : {
                            ...gridOptions, defaultColDef: {
                                flex: 1,
                                minWidth: 200
                            },
                        }}
                    />}
        </div>
    );
};
export default GridComponentClaims;