import React from 'react'
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import EligibilityClaimResultGrid from './EligibilityClaimResultGrid';

const EligibilityClaimResultdata = () => {
    const location = useLocation();
    const EligibilitysearchData = location.state?.searchData || [];
    const rowData = location.state?.rowData || {}


    return (
        <Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1.2em',
          }}>
            <Box sx={{ width: '25%' }}>
            {rowData?.Patient ? (
              <p>{rowData?.Patient}</p>
            ) : (
              <p>{`${EligibilitysearchData?.insuranceDiscoveryDto?.patientData?.firstName} ${EligibilitysearchData?.insuranceDiscoveryDto?.patientData?.lastName}`}</p>
            )}

          </Box>
       
            
          </Box>
          <Box sx={{ marginTop: '2em' }}>
        
          </Box>
          <EligibilityClaimResultGrid
          eligibilitycheckData={rowData}
          />
        </Box>
      )
}

export default EligibilityClaimResultdata



