import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import toast from "react-hot-toast";
import { bulk_Claims } from "../../src/services/ClaimService";
import styles from "../../src/css/importPatient.module.css";

const GridComponentBatchClaims = ({ pagename, searchData }) => {
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const renderedRecords = new Set(); // Track already rendered records

    const handleProcessClick = async () => {
        if (!searchData) {
            toast.error('Please upload a CSV file first.');
            return;
        }

        try {
            const previewResult = await bulk_Claims(searchData?.csvRecords);
            if (previewResult?.isSuccessful) {
                toast.success('Records read and validated successfully');
                navigate('/batchclaimresponse', { state: { searchData: previewResult } });
            } else {
                toast.error(`Error reading records: ${previewResult?.message}`);
            }
        } catch (error) {
            toast.error("Error processing the claims");
            console.error('Error in handleProcessClick:', error);
        }
    };

    useEffect(() => {
        if (!searchData?.csvRecords) {
            toast.error("Invalid search data.");
            return;
        }

        const fetchData = async () => {
            try {
                const data = searchData?.csvRecords;
                const flattenedRows = [];

                for (let i = 0; i < data?.length; i++) {
                    const flattenedDataObj = {
                        PFN: data?.[i]?.patientFirstName,
                        PLN: data?.[i]?.patientLastName,
                        PDOB: data?.[i]?.patientDOB,
                        INS: data?.[i]?.insuranceName,
                        DOS: data?.[i]?.dateOfService,
                        SCD: data?.[i]?.scheduleDate,
                        NPI: data?.[i]?.npi,
                        STATUS: data?.[i]?.status,
                        ID: data?.[i]?.patientId
                    };

                    // Only add records that haven't been rendered yet
                    if (!renderedRecords?.has(JSON.stringify(flattenedDataObj))) {
                        renderedRecords?.add(JSON.stringify(flattenedDataObj));

                        if (pagename === "BatchClaimResult") {
                            // Add to rowData with a delay
                            setRowData(prevRows => [...prevRows, flattenedDataObj]);
                            await new Promise(resolve => setTimeout(resolve, 3000));
                        } else if (pagename === "ClaimBatchSearchresultdata") {
                            flattenedRows?.push(flattenedDataObj);
                            setRowData(flattenedRows);
                        }
                    }

                }
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error("Error fetching data");
            }
        };
        fetchData();
    }, [pagename, searchData]);

    const statusCellRenderer = (params) => {
        const isChecked = params?.value === "True";
        return (
            <span>
                {isChecked ? '✅' : '❌'}
            </span>
        );
    };

    const DetailsButton = ({ data }) => {
        const handleClick = () => {
            navigate({
                pathname: '/Claimdetails1',
                search: `?id=${encodeURIComponent(data?.ID)}`,
            });
        };
        return (
            <Button className='primary' style={{ backgroundColor: 'green', border: 'none', color: 'white', fontSize: 'small' }} onClick={handleClick}>
                View Details
            </Button>
        );
    };

    const columnDefs = () => {
        if (pagename === "ClaimBatchSearchresultdata") {
            return [
                { headerName: 'PatientFirstName', field: 'PFN', width: 120 },
                { headerName: 'PatientLastName', field: 'PLN', sortable: true, filter: true },
                { headerName: 'PatientDOB', field: 'PDOB', filter: true },
                { headerName: 'InsuranceName', field: 'INS', filter: true },
                { headerName: 'DateOfService', field: 'DOS', width: 120 },
                { headerName: 'ScheduleDate', field: 'SCD', width: 120, filter: true },
                { headerName: 'Npi', field: 'NPI', width: 280, filter: true },
            ];
        } else {
            return [
                { headerName: 'ID#', field: 'ID', width: 120 },
                { headerName: 'PatientFirstName', field: 'PFN', width: 120 },
                { headerName: 'PatientLastName', field: 'PLN', sortable: true, filter: true },
                { headerName: 'PatientDOB', field: 'PDOB', filter: true },
                { headerName: 'InsuranceName', field: 'INS', filter: true },
                { headerName: 'DateOfService', field: 'DOS', width: 120 },
                { headerName: 'ScheduleDate', field: 'SCD', width: 120, filter: true },
                { headerName: 'Npi', field: 'NPI', width: 280, filter: true },
                { headerName: 'Status', field: 'STATUS', width: 120, filter: true, cellRenderer: statusCellRenderer },
                { headerName: 'Details', field: 'Details', width: 150, filter: true, cellRenderer: DetailsButton },
            ];
        }
    };

    const gridOptions = {
        defaultColDef: {
            flex: 1,
            minWidth: 200
        },
        pagination: true,
        paginationPageSize: 10,
    };

    return (
        <div className={`ag-theme-quartz ${styles.gridContainer}`}>
            <AgGridReact
                columnDefs={columnDefs()}
                rowData={rowData}
                gridOptions={gridOptions}
            />
            {pagename === "ClaimBatchSearchresultdata" && (
                <Box className={styles?.previewButtonContainer}>
                    <Grid item xs={12} sm={6} lg={6} textAlign="right">
                        <Button
                            variant='contained'
                            color='success'
                            className={styles?.downloadCsvTemplate}
                            onClick={handleProcessClick}
                        >
                            PROCESS
                        </Button>
                    </Grid>
                </Box>
            )}
        </div>
    );
};
export default GridComponentBatchClaims;