import { Box, Skeleton, Typography } from "@mui/material";
import { styles } from "../Pages/Profile/styles";
import { colors } from "../utils/colors";

const BoxComponent = ({ label, isLoading, value }) => {

    return (
        <Box sx={styles?.boxStyles}>
            <Typography sx={styles?.Typography} variant='h6'>{label}</Typography>
            <Typography
                sx={styles?.line} variant='h6'>{isLoading ? <Skeleton variant='text' width={100} /> : value}</Typography>
        </Box>
    )
};



export default BoxComponent;