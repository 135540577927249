// apiService.js
import axios from 'axios';
import toast from "react-hot-toast";
const API_BASE_URL = process.env.REACT_APP_API_URL;
export const get_payersdata = async (pageNumber,pageSize) => {
    const url = `${API_BASE_URL}/api/Payers/GetAllPayersData${pageNumber ? `?pageNumber=${pageNumber}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        toast.error("Error Fetching Data (NETWORK error)");
    }
};

export const get_GetAllPayersData = async (payerDetail) => {
    const url = `${API_BASE_URL}/api/Payers/GetAllPayersData/payerCode=${payerDetail?.payerCode}/payerName=${payerDetail?.payerCode}/address=${payerDetail?.address}/phoneNumber=${payerDetail?.phoneNumber}/status=${payerDetail?.status}`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        toast.error("Error Fetching Data (NETWORK error)");

    }
};

export const get_GetPayerCodeByName = async (PayerName) => {
    const url = `${API_BASE_URL}/api/Payers/GetPayerCodeByName?PayerName=${PayerName}`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.log("Error: ", error)
        toast.error("Error Fetching Data (NETWORK error)");
    }
};
