import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { styles } from './styles'
import CircleWrapper from '../../components/shared/CircleWrapper'
import { MdOutlineCreateNewFolder, MdOutlineNewLabel } from 'react-icons/md'
import { GrDocumentMissing } from 'react-icons/gr'
import { colors } from '../../utils/colors'
import { useNavigate } from 'react-router-dom'
import { getCountFromArray } from '../../utils/DataFormatters/PatientsOverviewGraphDataFormatters'

const PatientOverviewCards = ({data, isLoading, setSelectedCard}) => {
    const navigate = useNavigate()
    return (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexDirection={'column'} width={{ xs: '100%', md: '50%' }}>
            {data?.map(({label,data}, index) => {
                return (
                    <Box item xs={12} md={4}
                        width={'100%'}
                        key={index} paddingX={{ xs: 1, sm: 2 }}


                    >
                        <Box boxShadow={3} paddingY={2} paddingX={2} borderRadius={4}
                            marginY={2}
                            sx={styles.card}
                            onClick={() => setSelectedCard(index + 1)}
                        >
                            <CircleWrapper >
                                {index === 0 ? <MdOutlineCreateNewFolder fill={colors?.themeGreen} /> : index === 1 ? <GrDocumentMissing stroke={colors?.themeGreen} /> : <MdOutlineNewLabel fill={colors?.themeGreen} />}
                            </CircleWrapper>
                            <Typography variant="body1" sx={styles.cardText}>
                                {label}
                            </Typography>
                            <Typography variant="body1" sx={styles.cardValue} color={index === 2 ? colors?.danger : colors?.themeGreen}>
                            {isLoading ? <CircularProgress color="inherit" size={22}/> :  getCountFromArray(data)}
                            </Typography>
                        </Box>
                    </Box>
                )
            })}
        </Box>
    )
}

export default PatientOverviewCards