import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PatientOverviewCards from './PatientOverviewCards'
import DateFilterModal from './FilterModal'
import {formatDateMDY, formatDateToReadable, formatDateYMD } from '../../utils/DateFunctions'
import { getPatientOverviewChartDetails } from '../../services/addPatient'
import toast from 'react-hot-toast'
import {  patientsOverviewGraphData } from '../../utils/staticData'
import {  patientTabData } from './dataFormatters'
import HeadingPlusFilter from '../../components/HeadingPlusFilter'
import { dataLabels, dataSets } from '../../utils/DataFormatters/PatientsOverviewGraphDataFormatters'
import dayjs from 'dayjs'
import PatientOverviewGraphs from './PatientOverviewGraphs'
import PatientOverviewEasyFilter from './PatientOverviewEasyFilter'
import { getProviderName, handleLeftAction, handleRightAction } from './dashboardFunctions'

const PatientsOverview = () => {
    const [selectedFilter, setSelectedFilter] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [startDate, setStartDate]= useState(dayjs(new Date()))
    const [endDate, setEndDate] = useState(dayjs(new Date()))
    const [isLoading, setIsLoading] = useState(false)
    const [filter, setFilter] = useState(false)
    const [patientsOverviewData, setPatientsOverviewData] = useState({patientsOverviewGraphData})
    const [patientsCardData, setPatientsCardData] = useState([])
    const [selectedCard, setSelectedCard] = useState(null)
    const [lastSelectedFilter, setLastSelectedFilter] = useState('Today')
    const [dateRecord, setDateRecord] = useState({startDate,endDate,lastSelectedFilter:'Today'})
    const [providerOrganizationName, setProviderOrganizationName] = useState('')

    const dateFilterAction = (date) => {
        setSelectedFilter(`${formatDateYMD(new Date(date ?? startDate))}/${formatDateYMD(new Date(endDate))}`)
        setFilter(!filter)
    }

    const getOverviewData = async() => {
        try{ setIsLoading(true)
         const data = await getPatientOverviewChartDetails(selectedFilter)
         if(data?.newPatientsAdded){
             const labels = dataLabels(data)
             const datasets = dataSets(data, labels)
             setPatientsOverviewData({ labels, datasets })
             setPatientsCardData(data)
             setShowModal(false)
 
         }
        }
        catch(e){
             console.log(e,'error fetching data')
             toast.error('error fetching data')
        }
         finally{
             setIsLoading(false)
         }
     }

     useEffect(()=>{
        getOverviewData()
     },[selectedFilter, filter])

    useEffect(()=>{
        getProviderName(setProviderOrganizationName)
    },[])
    return (
        <Box>
            <HeadingPlusFilter 
            onClick={()=>setShowModal(true)} 
            heading={providerOrganizationName || 'Patients Overview'} 
            reset={selectedCard ? ()=>setSelectedCard(null) : null}
            children={ <PatientOverviewEasyFilter 
                date={
                    formatDateMDY(new Date(startDate)) === formatDateMDY(new Date(endDate)) 
                    ? 
                    `${formatDateToReadable(formatDateMDY(new Date(startDate)))}`
                    :
                    `${formatDateToReadable(formatDateMDY(new Date(startDate)))} -To- ${formatDateToReadable(formatDateMDY(new Date(endDate)))}`}
                    leftAction={() =>handleLeftAction(lastSelectedFilter,startDate,setStartDate,dateFilterAction,setDateRecord,dateRecord)}
                    rightAction={()=>handleRightAction(lastSelectedFilter,startDate,setStartDate,dateFilterAction,setDateRecord,dateRecord)}
                    disableRightAction={formatDateMDY(new Date(startDate)) === formatDateMDY(new Date(endDate)) }
                    onClick={()=>setShowModal(true)}
                />}
            />

            <Box display={'flex'} justifyContent={'space-between'} alignItems={'stretch'}
                flexDirection={{ xs: 'column', md: 'row' }}
            >

                <PatientOverviewCards data={patientTabData(patientsCardData)} isLoading={isLoading} setSelectedCard={setSelectedCard}/>
                <Box
                    width={{ xs: '100%', md: '50%' }}
                    padding={2}
                >
                    <PatientOverviewGraphs selectedCard={selectedCard} isLoading={isLoading} 
                            patientsOverviewData={patientsOverviewData}/>
                </Box>
            </Box >
            
       <DateFilterModal
       open={showModal}
       handleClose={()=>setShowModal(false)}
       startDate={startDate}
       endDate={endDate}
       setStartDate={setStartDate}
       setEndDate={setEndDate}
       action={dateFilterAction}
       isLoading={isLoading}
       setLastSelectedFilter={setLastSelectedFilter}
       dateRecord={dateRecord}
       lastSelectedFilter={lastSelectedFilter}
       />
        </Box>
    )
}
export default PatientsOverview