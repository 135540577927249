import dayjs from "dayjs"
import { get_GetPatientDataById } from "../../services/addPatient"
import { capitalizedFirstLetter, endStringWithSpace, failedGetApi } from "../../utils/reusableFunctions"
import { colors } from "../../utils/colors"

export const formatGridArray = (array) => {
    const formattedArray = array?.length ? array?.map(({ firstName, lastName, patientInsuranceDetails, appointments, phone, createdDate, id: ID, updatedDate, status}) => {
      
        return ({
            Date_Added: createdDate ? new Date(createdDate).toDateString() : '----',
            Appt: !appointments.length ? '----' : appointments,
            Patient: capitalizedFirstLetter(firstName) + ' ' + capitalizedFirstLetter(lastName) || '-----',
            Phone: phone || '----',
            Copay: '----',
            Deductible: '----',
            Insurance: !patientInsuranceDetails?.length ? '----' : patientInsuranceDetails[0],
            Date_Updated:  updatedDate ? new Date(updatedDate).toDateString() : '----',
            ID
        })
    }) : []
    return formattedArray
}

export const clear = (setPatientDetails, setFieldsErr) => {
    setPatientDetails({
        patientLastName: "",
        patientFirstName: "",
        patientDOB: null,
        phoneNumber: "",
        email: "",
        appointmentDate: null,
        insurancename: [],
        memberId: "",
        groupNumber: "",
        policyHolderRelationship: "",
        requestNpi: "",
    });
    setFieldsErr(false);
};

export const fetchPatientDetails = async (setPatientDetails, selectedPatient) => {
    try {
        const patientData = await get_GetPatientDataById(selectedPatient?.id)
        if (patientData?.patientInfoData) {
            const {
                firstName: patientFirstName,
                lastName: patientLastName,
                dob: patientDOB,
                phone: phoneNumber,
                email,
                patientInsuranceDetails: insuranceName,
                appointments,
                groupNumber,
                memberId,
                policyHolderRelationship
            } = patientData?.patientInfoData

            setPatientDetails({
                patientLastName,
                patientFirstName,
                patientDOB: dayjs(patientDOB),
                phoneNumber,
                email,
                appointmentDate: appointments?.length ? dayjs(appointments[0]) : null,
                insurancename: insuranceName,
                memberId,
                groupNumber,
                policyHolderRelationship,
            });
        }
        else {
            failedGetApi()
        }
    }
    catch {
        failedGetApi()
    }
}

export const fieldsErrCheck = (patientFirstName, patientLastName, patientDOB) => {
    return !patientFirstName || !patientLastName || !patientDOB
}

export const optionalFieldsProvidedCheck = (memberId, groupNumber, appointmentDate, insurancename, policyHolderRelationship, phoneNumber, email) => {
    return memberId || groupNumber || appointmentDate || insurancename || policyHolderRelationship || phoneNumber || email
}

export const requiredFieldsProvidedCheck = (patientFirstName, patientLastName, patientDOB) => {
    return patientFirstName && patientLastName && patientDOB
}

export const selectedCardHeading = (cardIndex) => {
    const heading = ['New Patients Added','Patients With Insurance','Patients Without Insurance']?.find((_,index) => index + 1 === cardIndex)
    return heading
}


export const selectedCardGraphData = (cardIndex,data) => {
    const {labels,datasets} = data
    let arrayLabels = []
    let arrayDataSets = []
    // checking if graph has repeated data added to handle the line graph
    if(labels?.length === 2 && labels?.[0] === labels?.[1]){
        arrayLabels = [labels?.[0]]
        if(cardIndex === 1){
            const matchedVal = datasets?.find(elem => elem?.label === 'New Patients Added')
            arrayDataSets = [{...matchedVal,data:[matchedVal?.data?.[0]]}]
        }
        if(cardIndex === 2){
            const matchedVal = datasets?.find(elem => elem?.label === "Patients having insurance")
            arrayDataSets = [{...matchedVal,data:[matchedVal?.data?.[0]]}]
        }
        if(cardIndex === 3){
            const matchedVal = datasets?.find(elem => elem?.label === "Patients missing insurance")
            arrayDataSets = [{...matchedVal,data:[matchedVal?.data?.[0]]}]
        }

        return(
            {
                labels: arrayLabels,
                datasets: [{
                    ...arrayDataSets?.[0],
                    borderRadius: 5,
                    barThickness: 8,
                    backgroundColor: cardIndex === 3 ? colors?.red : cardIndex === 2 ? colors?.yellow : colors?.themeGreen,
                }]
            }
        )
    }

    // else 
    else{
        if(cardIndex === 1){
            arrayDataSets = [datasets?.find(elem => elem?.label === 'New Patients Added')]
        }
        if(cardIndex === 2){
            arrayDataSets = [datasets?.find(elem => elem?.label === "Patients having insurance")]
        }
        if(cardIndex === 3){
            arrayDataSets = [datasets?.find(elem => elem?.label === "Patients missing insurance")]
        }
        return(
            {
                labels,
                datasets:arrayDataSets?.map((elem) => {return(
                    {
                        ...elem,
                        borderRadius: 5,
                        barThickness: 8,
                        backgroundColor: cardIndex === 3 ? colors?.red : cardIndex === 2 ? colors?.yellow : colors?.themeGreen,
                    }
                )})
            }
        )
    }
}
