import axios from 'axios';
import toast from "react-hot-toast";
import { DownloadCSV, getRequestNpi } from "../utils/reusableFunctions.js"
import { failedToSearchDataStr } from '../utils/staticData.js';
import { userGetAxios, userPostAxios } from './userApis.js';
import { getLocalData } from '../utils/localStorageFunctions.js';
import { setAllClaimsIds } from '../utils/globalFunctions.js';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const requestNpi = getRequestNpi();

export const get_claimsdata = async () => {
    const url = `${API_BASE_URL}/api/ClaimsStatus/pverify/GetClaimStatusResponse/49`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);

    }
};

export const get_GenerateEmptyClaimsCsvTemplateAsync = async () => {
    const url = `${API_BASE_URL}/api/ClaimsStatus/GenerateEmptyClaimsCsvTemplateAsync`;
    try {
        const response = await axios.get(url);
        return response?.data;
    } catch (error) {
        console.log(error)
    }
};

export const validateCsvFile = async (requestData) => {
    const url = `${API_BASE_URL}/api/ClaimsStatus/ValidateCsvFile?requestNpi=${requestNpi}`;
    try {
        const response = await axios.post(url, requestData);

        if (response && response?.data && response?.data?.message) {
            toast?.success(response?.data?.message);
        }
        if (response?.data?.csvFileByteArray) {
            DownloadCSV(response?.data?.csvFileByteArray, `Validation_Response (${new Date().getUTCDate()}-${new Date().getUTCMonth() + 1}-${new Date().getUTCFullYear()})?.csv`);
        }
        return response?.data;
    } catch (error) {
        if (error?.response?.data?.csvFileByteArray) {
            DownloadCSV(error?.response?.data?.csvFileByteArray, `Validation_Response (${new Date().getUTCDate()}-${new Date().getUTCMonth() + 1}-${new Date().getUTCFullYear()})?.csv`);
        }
        toast?.error(error?.response?.data?.errors ?? 'Failed to validate CSV');
    }
};

export const readCsvRecords = async (requestData) => {
    const url = `${API_BASE_URL}/api/ClaimsStatus/ReadCsvRecords?requestNpi=${requestNpi}`;
    try {
        const response = await axios.post(url, requestData);

        if (response && response?.data && response?.data?.message) {
            toast?.success(response?.data?.message);
        }

        return response?.data;
    } catch (error) {
        toast?.error(error?.response?.data?.errors ?? 'Failed to read CSV');
    }
};

export const bulk_Claims = async (requestData) => {
    const url = `${API_BASE_URL}/api/ClaimsStatus/AddBulkClaims?requestNpi=${requestNpi}`;
    try {
        const response = await axios.post(url, requestData);
        if (response && response?.data && response?.data?.message) {
            toast?.success(response?.data?.message);
        }
        if (response?.data?.csvFileByteArray) {
            DownloadCSV(response?.data?.csvFileByteArray, `Downloaded_Response (${new Date().getUTCDate()}-${new Date().getUTCMonth() + 1}-${new Date().getUTCFullYear()})?.csv`);
        }
        return response?.data;
    } catch (error) {
        if (error?.response?.data?.csvFileByteArray) {
            DownloadCSV(error?.response?.data?.csvFileByteArray, `Downloaded_Response (${new Date().getUTCDate()}-${new Date().getUTCMonth() + 1}-${new Date().getUTCFullYear()})?.csv`);
        }
        toast?.error(error?.response?.data?.errors ?? 'Failed to upload CSV');
    }
};


export const post_Scheduletask = async (Id, data) => {
    const url = `${API_BASE_URL}/api/ScheduleTask/AddScheduleTask/${Id}`;

    try {
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error('Error scheduling claim/insurance request:', error);

    }
};

export const post_claimsstatusinquiry = async (selectedVendors, requestData) => {
    const results = [];

    // Create an array of promises for each vendor request
    await Promise.all(selectedVendors.map(async (vendor) => {
        const url = `${API_BASE_URL}/api/ClaimsStatus/${vendor.vendor_name}/PostClaimStatusInquiry`;
        try {
            // Merge vendor details with the request data under a "vendor" object
            const vendorRequestData = {
                ...requestData,   // Include existing request data
                vendor: {         // Nest vendor details inside a "vendor" object
                    vendor_id: vendor.vendor_id,  // Add vendor_id to the "vendor" object
                    vendor_name: vendor.vendor_name // Add vendor_name to the "vendor" object
                }
            };

            const response = await axios.post(url, vendorRequestData, {
                headers: {
                    'Content-Type': 'application/json',
                    // Add any other headers as needed
                },
            });
            // Push the response data to results array
            results.push({ vendor: vendor.vendor_name,vendor_id: vendor.vendor_id, data: response.data });
        } catch (error) {
            // If there's an error, push an 'Error' item to results array
            results.push({ vendor: vendor.vendor_name, data: 'Error' });
            toast.error(`Failed to fetch data for vendor: ${vendor.vendor_name}`);
            console.error(`Error fetching data for vendor ${vendor.vendor_name}:`, error);
        }
    }));
    return results;
};


export const get_GetAllClaimsStatusData = async (pageNumber, pageSize) => {

    const url = new URL('/api/ClaimsStatus/GetAllClaimsStatusData', API_BASE_URL);
    const params = new URLSearchParams();

    if (pageNumber) params?.append('pageNumber', pageNumber);
    if (pageSize) params?.append('pageSize', pageSize);
    params?.append('npiId', getLocalData('selectedNpi')?.id ?? '')

    url.search = params.toString();

    //const url = `${API_BASE_URL}/api/ClaimsStatus/GetAllClaimsStatusData${pageNumber ? `?pageNumber=${pageNumber}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}`;

    try {
        const response = await axios.get(url?.toString());
        if(response?.data){
            setAllClaimsIds(response?.data?.claimsStatusSimplifiedDtos?.map(({patientId})=>patientId))
        }
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);

    }
};

export const get_NpiDataByNpiNumber = async (npiNumber) => {
    const url = `${API_BASE_URL}/api/Npi/GetNpiDataByNpiNumber?npiNumber=${npiNumber}`;
    try {
        const response = await axios.post(url, npiNumber);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);

    }
};

export const post_NpiDataByNpiId = async (requestData) => {
    const url = `${API_BASE_URL}/api/Npi/GetNpiDataByNpiIds`;
    try {
        const response = await axios.post(url, requestData, {
            headers: {
                'Content-Type': 'application/json',
                // Add any other headers as needed
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);

    }
};


export const GetPatientClaimStatus = async (id) => {
    const url = `${API_BASE_URL}/api/ClaimsStatus/GetClaimStatusByPatientId/${id}`;

    try {
        const response = await axios.get(url);
        return { data: { ...response.data?.claimStatusRoot, ...response.data?.patientInfoResponse } };

    } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data')
    }
}


export const claimTabData = (data, setClaimData, claimData) => {
    const basicDetails = [
        {
            name: 'Member Policy Id',
            value: data?.subscriber?.memberPolicyId ?? '',
        },
        {
            name: 'Payer Claim No',
            value: data?.claimStatuses?.otherIndentificationInfo?.find(elem => elem?.qualifier === 'Payor;s Claim Number')?.id ?? '',
        },
        {
            name: 'Payer Id',
            value: data?.payer?.info?.id ?? '',
        },
        {
            name: 'Payer Name',
            value: data?.payer?.info?.name ?? '',
        },
        {
            name: 'Payer Phone',
            value: data?.payer?.contactInfo ?? '',
        },
        {
            name: 'Subscriber First Name',
            value: data?.subscriber?.firstName ?? '',
        },
        {
            name: 'Subscriber Last Name',
            value: data?.subscriber?.name ?? ''
        }
    ]
    const claimDates = [
        {
            name: 'Response Date',
            value: data?.responseDate ?? ''
        },
        {
            name: 'Service Date',
            value: data?.claimStatuses?.dates?.find(elem => elem?.qualifier === 'Service')?.value ?? ''
        },
        {
            name: 'Claim Reciept Date',
            value: data?.claimReceiptDate ?? ''
        },
        {
            name: 'Adjuction Claim Date',
            value: data?.claimStatuses?.statusInfo?.adjudicationFinalizedDate ?? ''
        }
    ]
    const claimSummary = [
        {
            name: 'Charge Amount',
            value: data?.claimStatuses?.statusInfo?.chargeAmount ?? ''
        },
        {
            name: 'Adjuction Date',
            value: data?.claimStatuses?.statusInfo?.adjudicationFinalizedDate ?? ''
        },
        {
            name: 'Check Date',
            value: data?.CheckDate ?? ''
        },
        {
            name: 'Payer Claim No',
            value: data?.checkDate ?? '',
        },
        {
            name: 'Check No',
            value: data?.claimStatuses?.statusInfo?.checkNumber ?? ''
        }
    ]

    const claimDetailDates = [
        {
            name: 'Benefit Exhaust Date',
            value: data?.benefitExhaustDate ?? ''
        },
        {
            name: 'Claim Reciept Date',
            value: data?.claimReceiptDate ?? ''
        },
        {
            name: 'Date Check Cashed',
            value: data?.dateCheckCashed ?? ''
        },
    ]

    const accNumbers = [
        {
            name: 'Patient Account Number',
            value: data?.claimStatuses?.otherIndentificationInfo?.find(elem => elem?.qualifier === 'Patient Account Number')?.id ?? ''
        },
        {
            name: 'Internal Account Number',
            value: data?.internalAccountNumber ?? ''
        }
    ]

    const statusDetails = [
        {
            name: 'Status Code',
            value: data?.claimStatuses?.statusInfo?.statuses?.find(elem => elem?.statusCode)?.statusCode ?? ''
        },
        {
            name: 'Check status',
            value: data?.claimStatuses?.statusInfo?.statuses?.find(elem => elem?.claimStatus)?.claimStatus ?? ''
        },
        {
            name: 'Status Description',
            value: data?.statusDescription ?? ''
        }
    ]

    const categoryDetails = [
        {
            name: 'Category Code',
            value: data?.categoryCode ?? ''
        },
        {
            name: 'Category Description',
            value: data?.categoryDescription ?? ''
        }
    ]


    const subscriberDetails = [
        {
            name: 'Subscriber Id',
            value: data?.subscriber?.id ?? ''
        },
        {
            name: 'Billing Entity Name',
            value: data?.billingEntityName ?? ''
        },
        {
            name: 'Revenue CD',
            value: data?.revenueCd ?? ''
        }
    ]

    const authorizationDetails = [
        {
            name: 'SVC Line QTY',
            value: data?.svcLineQty ?? ''
        },
        {
            name: 'Authorization Case Id',
            value: data?.authorizationCaseId ?? ''
        },
        {
            name: 'Authorization Description',
            value: data?.authorizationDescription ?? ''
        },
        {
            name: 'Discount Days',
            value: data?.discountDays ?? ''
        },
        {
            name: 'Lifetime Reserve Days',
            value: data?.lifetimeReserveDays ?? ''
        },
        {
            name: 'Description',
            value: data?.description ?? ''
        }
    ]


    const chargeDetails = [
        {
            name: 'Charge Amount',
            value: data?.claimStatuses?.statusInfo?.chargeAmount ?? ''
        },
        {
            name: 'Paid Amount',
            value: data?.claimStatuses?.statusInfo?.paymentAmount ?? ''
        },
        {
            name: 'Allowed Amount',
            value: data?.allowedAmount ?? ''
        },
        {
            name: 'Co Insurance Amount',
            value: data?.coInsuranceAmount ?? ''
        },
        {
            name: 'Deductible Amount',
            value: data?.deductibleAmount ?? ''
        },
        {
            name: 'Co Payment Amount',
            value: data?.coPaymentAmount ?? ''
        }
    ]
    const otherChargesDetails = [
        {
            name: 'Non-Recovered Amount',
            value: data?.nonRecoveredAmt ?? ''
        },
        {
            name: 'Paid Amount by Other Insurance',
            value: data?.paidAmtByOtherInsurance ?? ''
        },
        {
            name: 'Lifetime Reserve Amount',
            value: data?.lifetimeReserveAmount ?? ''
        },
        {
            name: 'Managed Care Panelty Amount',
            value: data?.managedCarePenaltyAmt ?? ''
        },
        {
            name: 'Interest Paid',
            value: data?.interestPaid ?? ''
        }
    ]

    const denialDetails = [
        {
            name: 'Denial Code 1',
            value: data?.denialCode ?? ''
        },
        {
            name: 'Denial Reason',
            value: data?.DenialReason ?? ''
        }
    ]

    setClaimData({ ...claimData, basicDetails, claimDates, claimSummary, claimDetailDates, accNumbers, statusDetails, categoryDetails, subscriberDetails, authorizationDetails, chargeDetails, otherChargesDetails, denialDetails })
}


export const getAllScheduledTransactionsDetailsClaims = async (pageNumber, pageSize) => {
    const url = `${API_BASE_URL}/api/ScheduleTask/GetAllScheduleTasks${pageNumber ? `?pageNumber=${pageNumber}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}`
    const payload = {
        userId: JSON.parse(localStorage?.getItem('user'))?.userId,
        scheduleType: 'Claims'
    }
    const response = await userPostAxios(url, payload)
    return response?.data;

}

export const checkScheduleTask = async (id, reqParams) => {
    const url = `${API_BASE_URL}/api/ScheduleTask/AddScheduleTask/${id}`
    const payload = {
        userId: JSON.parse(localStorage?.getItem('user'))?.Id,
        ScheduleType: "Claims",
        ScheduleDate: new Date()?.toISOString(),
        ClaimsRequestParameters: reqParams

    }
    const response = await userPostAxios(url, payload)
    return response?.data
}

export const ApprovedClaims = async () => {
    const url =`${API_BASE_URL}/api/ClaimsStatus/ApprovedClaimsCount?npiId=${getLocalData('selectedNpi')?.id}`
    const response = await userGetAxios(url)
    return response
}

export const pendingClaims = async () => {
    const url =`${API_BASE_URL}/api/ClaimsStatus/GetPendingClaimsCounts?npiId=${getLocalData('selectedNpi')?.id}`
    const response = await userGetAxios(url)
    return response
}

export const mostCommonDenialReason = async () => {
    const url =`${API_BASE_URL}/api/ClaimsStatus/GetMostCommonDenialReasonForClaims?npiId=${getLocalData('selectedNpi')?.id}`
    const response = await userGetAxios(url)
    return response
}