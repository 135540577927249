import React, { useEffect, useState } from 'react'

const UseWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth)
        })
    }, [width])

    return { width };
}

export default UseWidth