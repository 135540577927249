
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
} from 'chart.js';
import { useEffect, useState } from 'react';
import { colors } from '../../utils/colors';
import { barPattern, options } from './patientClaimsChartStyle';
const PatientsClaimsChart = () => {

    // states
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // registering chart before use
    ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);




    // making chart data
    const data = {
        labels: [1, 2, 3, 4, 5, 6, 7],
        datasets: [
            {
                data: [1, 2, 3, 4, 5, 6, 7],
                label: '',
                backgroundColor: colors?.themeGreen,
                borderRadius: 5,
                barThickness: 8
            }
        ]
    };


    return (
        <Bar options={options} data={data} plugins={[barPattern]} height={isSmallScreen ? '100%' : '70px'} />

    )
};

export default PatientsClaimsChart;