import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid, Tabs } from '@mui/material';
import styles from '../../css/Dentaldetails1.module.css'; 
import { useState, useEffect } from 'react';
import MyTabs from '../../components/tabs/MyTabs.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import PayerDetailsCard from '../../components/shared/PayerDetailsCard.js';
import { BsFillCalendarDateFill } from "react-icons/bs";
import { HiCurrencyDollar } from "react-icons/hi2";
import { MdOutlineNumbers } from "react-icons/md";
import HorizontalBox from '../../components/shared/HorizontalBox.js';
import { post_dentaleligibilitybenefit, GetDentalEligibilityByPid } from '../../services/DentalEligibilityService.js';
import GreenButton from '../../components/claims/GreenButton.js';
import DentalTabDetails from '../../components/detnalTab/DentalTabDetails.js';
import { FaHandshakeAngle } from "react-icons/fa6";
import { SiInstatus } from "react-icons/si";
import toast from 'react-hot-toast';
import { colors } from '../../utils/colors.js';
import DetailsHeader from '../../components/shared/DetailsHeader.js';
import { AiFillCreditCard } from "react-icons/ai";
import { PiListNumbersFill } from "react-icons/pi";

function Dentaldetails1() {
    const { formWrapper, container, centeredBox, fullWidthBox, marginTopBox, textCenter } = styles; // Use imported styles
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);
    const [dentalResponse, setdentalResponse] = useState();
    const [tabs, setTabs] = useState([]);
    const [topCardItems, setTopCardItems] = useState([]);
    const [topRightButtons] = useState([
        { name: 'Add Patient', action: () => navigate('/patients?preSelectedTab=1') },
        { name: 'New Search', action: () => navigate('/ClaimTab?preSelectedTab=1') },
        { name: 'Back to Results', action: () => navigate(-1) }
    ]);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const searchId = new URLSearchParams(location.search).get('id');

    const handleTabChange = (newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    const fetchDentalData = async () => {
        try {
            setIsLoading(true);
            const { data } = await GetDentalEligibilityByPid(searchId);
            if (data?.benefitDetails?.length) {
                const cardItems = [
                    {
                        name: 'Payer',
                        value: data?.payerName ?? 'N/A',
                        icon: <SiInstatus fill={colors?.themeGreen} />
                    },
                    {
                        name: 'MemberId',
                        value: data?.miscellaneousInfoSummary?.memberID ?? 'N/A',
                        icon: <SiInstatus fill={colors?.themeGreen} />
                    },
                    {
                        name: 'Policy Type',
                        value: data?.planCoverageSummary?.policyType ?? 'N/A',
                        icon: <SiInstatus fill={colors?.themeGreen} />
                    },
                    {
                        name: 'Plan Coverage Status',
                        value: data?.planCoverageSummary?.status ?? 'N/A',
                        icon: <SiInstatus fill={colors?.themeGreen} />
                    }
                ];
                setTopCardItems(cardItems);
            }
            setdentalResponse(data);
            setIsLoading(false);
        } catch {
            toast.error('Failed to get data');
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDentalData();
    }, []);

    useEffect(() => {
        const newTabs = [
            { 
                label: "Plan Coverage Summary", 
                value: <DentalTabDetails data={{ ...dentalResponse }} tabnum={"1"} />
            },
            { 
                label: "Basic Details", 
                value: <DentalTabDetails data={{ ...dentalResponse }} tabnum={"2"} />
            },
            {
                label: "DemographicInfo",
                value: <DentalTabDetails data={{ ...dentalResponse }} tabnum={"3"} />
            },
            {
                label: "Benefit Details Limitations ",
                value: <DentalTabDetails data={{ ...dentalResponse }} tabnum={"4"} />
            }
        ];
        setTabs(newTabs);
    }, [dentalResponse]);

    return (
        isLoading ?
            <Box className={centeredBox}>
                <CircularProgress color="success" size={22} />
            </Box> :
            <Box className={fullWidthBox}>
                <DetailsHeader topRightButtons={topRightButtons} name={`${dentalResponse?.firstName} ${dentalResponse?.lastName}`} dob={dentalResponse?.dob} />
                <Box className={marginTopBox}>
                    <Box className={formWrapper}>
                        <Grid container rowSpacing={3} columnSpacing={3}>
                            {topCardItems?.map((elem, index) => (
                                <Grid item xs={12} sm={6} lg={3} key={index}>
                                    <PayerDetailsCard>
                                        {elem?.icon}
                                        <Box className={textCenter}>
                                            <Typography className='name-text' >{elem?.name}</Typography>
                                            <Typography>{elem?.value}</Typography>
                                        </Box>
                                    </PayerDetailsCard>
                                </Grid>
                            ))}
                        </Grid>
                        {!!tabs?.length &&
                            <Box className={fullWidthBox}>
                                <Tabs sx={{ mt: 5 }}>
                                    <MyTabs tabs={tabs} onChange={handleTabChange} activeTab={tabIndex} />
                                </Tabs>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
    );
}

export default Dentaldetails1;
