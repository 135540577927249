import React, { useState, useEffect } from 'react';
import ClaimTabDetails from '../../components/claims/ClaimTabDetails';

const Claimdetail = ({ data }) => {
    return (
        <div>
            <div >
                <ClaimTabDetails data={data} />

            </div>
        </div>
    );
};

export default Claimdetail;
