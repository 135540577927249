import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import React, { useEffect, useState } from 'react';
import {  Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { get_GetPatientDataById } from '../services/addPatient';
import { get_dentaleligibility } from '../services/DentalEligibilityService';
import toast from "react-hot-toast";
import './GridComponentDentalEligibility.css'; 
import { sharedStyles } from '../sharedStyles';
const GridComponentDentalEligibility = ({ pagename, searchData }) => {
    const [rowData, setRowData] = useState([]);
    let navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (pagename === "Eligibility") {
                    const data = await get_dentaleligibility();
                    const flattenedRows1 = [];
                    for (var i = 0; i < data?.length; i++) {
                        const flattenedDataObj = {};
                        var patientdetails = await get_GetPatientDataById(data[i]?.patientId);
                        if(patientdetails){
                        var patientInfo = patientdetails?.patientInfoData;
                        flattenedDataObj.ID = patientInfo?.id ?? '';
                        flattenedDataObj.Patient = `${patientInfo?.firstName ?? ''} ${patientInfo?.lastName ?? ''}`;
                        flattenedDataObj.Phone = patientInfo?.phone ?? '';
                        flattenedDataObj.DOB = patientInfo?.dob ?? '';
                        flattenedDataObj.Insurance = patientInfo?.patientInsuranceDetails?.[0] ?? '';
                        }else{
                        flattenedDataObj.ID = '';
                        flattenedDataObj.Patient = '';
                        flattenedDataObj.Phone = '';
                        flattenedDataObj.DOB = '';
                        flattenedDataObj.Insurance = '';
                        }
                        flattenedDataObj.Status = data[i]?.category ?? '';
                        flattenedRows1.push(flattenedDataObj);
                    }
                    setRowData(flattenedRows1);
                } else {
                    const data = searchData;
                    const flattenedRows1 = [];
                    const flattenedDataObj = {};
                    try {
                        flattenedDataObj.ID = data?.patientData?.id ?? '';
                        flattenedDataObj.Patient = data?.dentalEligibilityRoot?.demographicInfo?.subscriber?.fullName ?? '';
                        flattenedDataObj.Phone = data?.dentalEligibilityRoot?.demographicInfo?.subscriber?.zip ?? '';
                        flattenedDataObj.DOB = data?.dentalEligibilityRoot?.demographicInfo?.subscriber?.doB_R ?? '';
                        flattenedDataObj.Insurance = data?.dentalEligibilityRoot?.payerName ?? '';
                        flattenedDataObj.Status = '';
                    } catch (error) {
                        toast.error("Error No Response");
                    }

                    flattenedRows1.push(flattenedDataObj);
                    setRowData(flattenedRows1);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const statusCellRenderer = (params) => {
        const statusValue = params?.value;
        return <span className="statusSpan">{statusValue}</span>;
    };

    const DetailsButton = ({ data }) => {
        const handleClick = () => {
            navigate({
                pathname: '/Dentaldetails1',
                search: `?id=${encodeURIComponent(data?.ID)}`,
            });
        };
        return (
            <Button
                className='primary'
                style={sharedStyles?.primaryButtonStyles}
                onClick={handleClick}
            >
                View Details
            </Button>
        );
    };

    const columnDefs = () => {
        if (pagename === "Eligibility") {
            return [
                { headerName: 'ID#', field: 'ID', width: 120 },
                { headerName: 'Date', field: 'Date', sortable: true, filter: true },
                { headerName: 'Patient', field: 'Patient', filter: true },
                { headerName: 'Phone', field: 'Phone', filter: true },
                { headerName: 'Date of Birth', field: 'DOB', width: 120 },
                { headerName: 'Insurance Carrier', field: 'Insurance', width: 120, filter: true },
                { headerName: 'Status', field: 'Status', cellRenderer: statusCellRenderer, width: 280, filter: true },
                { headerName: 'Details', field: 'Details', width: 150, filter: true, cellRenderer: DetailsButton },
            ];
        } else {
            return [
                { headerName: 'ID#', field: 'ID', width: 120 },
                { headerName: 'Date', field: 'Date', sortable: true, filter: true },
                { headerName: 'Patient', field: 'Patient', filter: true },
                { headerName: 'Phone', field: 'Phone', filter: true },
                { headerName: 'Date of Birth', field: 'DOB', width: 120 },
                { headerName: 'Insurance Carrier', field: 'Insurance', width: 120, filter: true },
                { headerName: 'Status', field: 'Status', cellRenderer: statusCellRenderer, filter: true },
                { headerName: 'Details', field: 'Details', width: 120, filter: true, cellRenderer: DetailsButton },
            ];
        }
    };

    const gridOptions = {
        defaultColDef: {
            flex: 1,
            minWidth: 200,
        },
        pagination: true,
        paginationPageSize: 10,
        domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
    };

    return (
        <div className="ag-theme-quartz ag-theme-quartz-container" >
            <AgGridReact
                columnDefs={columnDefs()}
                rowData={rowData}
                gridOptions={gridOptions}
            />
        </div>
    );
};
export default GridComponentDentalEligibility;