import React from 'react'
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
} from 'chart.js';
import { barPattern, options } from './patientClaimsChartStyle';

const SelectedCardChart = ({data}) => {


    // registering chart before use
    ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);


    return (
        <Bar options={options} data={data} plugins={[barPattern]}  />

    )
};

export default SelectedCardChart