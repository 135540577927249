import { BorderBottom } from "@mui/icons-material";
import { colors } from "../../utils/colors";

export const styles = {
    dropdownBtn: {
        background: colors?.white,
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${colors?.themeGreen}`,
        padding: '0.5em',
        fontSize: '13px',
        marginRight: ['1em', '1em', '1em', '0']
    },
    skeleton: {
        fontSize: '1rem',
        minWidth: '120px'
    },
    menuItem: {
        padding: '0.5em 0.5em',
        borderBottom: '1px solid white'
    },
    logoutIcon: {
        marginRight: '0.3em',
        fontSize: '20px'
    },
    link: {
        textDecoration: 'none',
        color: colors?.black,
    },
    menuItemIcon: {
        marginRight: '0.3em',
        fontSize: '20px'
    },
    menu: {
        maxHeight: '300px',
        padding: 0
    },
    name: {
        textTransform: 'capitalize', color: colors?.black, fontWeight: 'bold'
    }
}