
import React, { useEffect } from 'react'
import ShadowBoxContainer from '../../components/ShadowBoxContainer'
import PatientsOverview from './PatientsOverview'
import PatientsClaimsInsight from './PatientsClaimsInsight'
import { getAllClaimsIds, getAllDiscoveryIds, getAllPatientsIds } from '../../utils/globalFunctions'

const Dashboard = () => {
    //useEffect for initial APIs to be used all over the app
    useEffect(()=>{
        getAllPatientsIds()
        getAllDiscoveryIds()
        getAllClaimsIds()
    },[])
    return (
        <ShadowBoxContainer >
            <PatientsOverview />
            <PatientsClaimsInsight />
        </ShadowBoxContainer>
    )
}

export default Dashboard