import React from 'react';
import { Box } from '@mui/material';

import EligibilityPaginatedTable from './EligibilityPaginatedTable';

const Eligibility = () => {

  return (
    <Box>

      <Box sx={{ marginTop: '2em' }}>
        <EligibilityPaginatedTable/>
      </Box>
    </Box>
  );
};

export default Eligibility;
