import React from 'react'
import HorizontalBox from '../shared/HorizontalBox';
import { Typography } from '@mui/material';
import './styles.css'

export const phoneCellRenderer = (params,onClick) => {
    return (
      <HorizontalBox className={'phoneCellRenderer'} onClick={onClick}>
        {params?.data?.Phone !== 'N/A'  && <Typography>{params?.data?.Phone}</Typography>}
      </HorizontalBox>
    )
};

