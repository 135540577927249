import { Button, CircularProgress } from '@mui/material'
import React from 'react'
import styles from "../../css/discoveryDetails.module.css";

const SyncButton = ({onClick,isLoading}) => {
    const { button } = styles; 
  return (
    <Button variant="outlined" className={button} onClick={onClick} disabled={isLoading} >
    {isLoading ?  <CircularProgress color="success" size={22} /> : 'Sync with'}
    </Button>
  )
}

export default SyncButton