import React from 'react'
import HorizontalBox from './HorizontalBox'
import { Typography } from '@mui/material'
import { colors } from '../../utils/colors'
import { sharedStyles } from '../../sharedStyles'

const HorizontalTextPlusHeading = ({ heading, text }) => {
    return (
        <HorizontalBox>
            <Typography sx={sharedStyles?.mr1} fontWeight={'bold'}>
                {heading}:
            </Typography>
            <Typography color={colors?.themeGreen} >
                {text}
            </Typography>
        </HorizontalBox>
    )
}

export default HorizontalTextPlusHeading