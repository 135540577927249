import React from "react";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { bulk_Patient, get_GenerateEmptyCSVAsync } from "../../services/addPatient";
import { DownloadCSV } from "../../utils/reusableFunctions.js"
import toast from "react-hot-toast";
import styles from "../../css/importPatient.module.css"


const ImportPatient = () => {

  const generateCSV = async () => {
    try {
      const response = await get_GenerateEmptyCSVAsync();
      if (response?.csvFileByteArray) {
        DownloadCSV(response?.csvFileByteArray, "Upload_Patient_Template.csv");
      } else {
        toast('Error while getting empty csv file');
      }
    } catch (error) {
      console.error('Error generating CSV file:', error);
    }
  };

  const handleChange = (e) => {
    const file = e?.target?.files[0];
    const formData = new FormData();
    formData.append("csvFile", file);
    bulk_Patient(formData);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={6}>
          <p>* Upload a new CSV file</p>
        </Grid>
        <Grid item xs={12} sm={6} lg={6} textAlign="right">
          <Button variant='contained' color='success' onClick={generateCSV} className={styles?.downloadCsvTemplate}>
            Download CSV Template
          </Button>
        </Grid>
      </Grid>
      {/* <p>* Maximum Size of the file should be 20MB</p> */}
      <Box className={styles?.uploadCsv}>
        <button className={styles?.uploadCsvButton} >
          <CloudUploadIcon />
          <span>Upload CSV</span>
        </button>
        <input
          type="file"
          onChange={handleChange}
          className={styles?.inputTag}
        />
      </Box>
    </>
  );
};

export default ImportPatient;
