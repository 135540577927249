import React, { useContext, useState } from 'react';
import { Box, Button, IconButton, Menu, MenuItem, Paper, Skeleton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { getLocalData } from '../../utils/localStorageFunctions';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { styles } from './styles';
import { colors } from '../../utils/colors';
import { ContextProvider } from '../../context/NotificationContext';


const DropdownHeader = ({ handleOptionSelect }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [subAnchorEl, setSubAnchorEl] = useState(null);
    const firstname = getLocalData("user")?.firstName;
    const lastname = getLocalData("user")?.lastName;
    const { notifications, newNotifications } = useContext(ContextProvider);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSubAnchorEl(null)
    };

    const logout = () => {
        // Handle logic for option 1
        handleOptionSelect();
        handleClose();
    };

    const handleOption2 = () => {
        // Handle logic for option 2
        handleClose();
    };

    const handleSubmenuClick = (event) => {
        setSubAnchorEl(event.currentTarget);
    };

    const handleSubmenuClose = () => {
        setSubAnchorEl(null);
    };

    const onNpiChange = (npiInfo) => {
        localStorage.setItem('selectedNpi', JSON.stringify(npiInfo));
        window.location.reload()
    };

    let npiInfoList = JSON.parse(localStorage.getItem('user'))?.npiInfoList;
    let selectedNpiInfo = JSON.parse(localStorage.getItem('selectedNpi'));

    return (
        <div>
            <Button onClick={handleClick} sx={styles?.dropdownBtn} className='dropdown-header' size='small' variant="text" >
                <PersonOutlineIcon sx={{ color: colors?.themeGreen }} />
                {
                    firstname && lastname ?
                        <Typography variant='caption' sx={styles?.name}>{firstname} {lastname?.slice(0, 1)}.</Typography>
                        :
                        <Skeleton variant="text" sx={styles?.skeleton} />
                }
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={styles?.menu}
                transformOrigin={{
                    horizontal: 'center'
                }}
            >
                <Box sx={{ background: colors?.themeGreen, color: colors?.white, width: '150px' }}>
                    <MenuItem component={Link} to={'/userSettings'} sx={styles?.menuItem} onClick={handleOption2}>
                        Settings
                    </MenuItem>
                    <MenuItem sx={styles?.menuItem} onClick={handleSubmenuClick}>
                        <Typography>
                            NPIs
                        </Typography>
                        <ArrowDownwardIcon sx={{ marginLeft: 'auto', fontSize: '22px' }} />
                    </MenuItem>

                    <MenuItem sx={styles?.menuItem}
                        onClick={logout}
                    >
                        Logout
                    </MenuItem>

                    <Menu id='sds' transformOrigin={{
                        horizontal: 'center'
                    }} anchorEl={subAnchorEl} open={Boolean(subAnchorEl)} onClose={handleSubmenuClose}>
                        {npiInfoList && npiInfoList.length > 0 && npiInfoList.map((x) => (
                            <MenuItem key={x.id} sx={styles?.menuItem} onClick={(e) => {
                                onNpiChange(x)
                                handleSubmenuClose(e)
                            }} selected={selectedNpiInfo && x.id == selectedNpiInfo.id}>
                                {x.name[0].toUpperCase() + x.name.slice(1).toLowerCase()}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Menu>
        </div>
    );
};



export default DropdownHeader;
