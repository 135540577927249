import { colors } from "../../utils/colors";

export const options = {
    resposive: true,
    scales: {
        x: {
            grid: {
                display: false
            },
            ticks: {
                display: true
            }
        },
        y: {
            border: { dash: [6, 6] },
            grid: {
                color: colors?.gray,
                offset: true,
                drawOnChartArea: true
            },

            beginAtZero: true
        }
    },
    plugins: {
        legend: {
            display: false
        }
    }
};

// making custom chart pattern
export const barPattern = {
    id: 'barPattern',
    beforeDatasetsDraw(chart, args, pluginOptions) {
        const {
            ctx,
            chartArea: { top, bottom, height },
            scales: { x, y }
        } = chart;
        ctx.save();
        const width = chart.getDatasetMeta(0)?.data[0]?.width ?? 0;
        chart.getDatasetMeta(0)?.data.forEach((dataPoint, index) => {
            ctx.fillStyle = colors?.lightGray;
            ctx.fillRect(
                x.getPixelForValue(index) - width / 2,
                top,
                width,
                height - 0.5
            );
        });
    }
};