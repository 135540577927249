import React from 'react'
import HorizontalBox from './shared/HorizontalBox'
import { Button, Typography } from '@mui/material'
import { styles } from './style'
import { sharedStyles } from '../sharedStyles'
import ResponsiveHorizontalBox from './shared/ResponsiveHorizontalBox'

const HeadingPlusFilter = ({onClick,heading,reset,children,simpleFilter}) => {
  return (
    <ResponsiveHorizontalBox sx={sharedStyles?.justifyBetween}
    itemsCenter
    >
    <Typography variant="h5">
        {heading}
    </Typography>
    {children && children}
    {/* <HorizontalBox> */}
{
!simpleFilter && 
  <Button
    onClick={() => onClick && reset ? reset() : {}}
    sx={reset ? {...sharedStyles?.whiteBtn,...{width:'fit-content'}} : {...sharedStyles?.whiteBtnDisabled,...{width:'fit-content'}}}> Reset</Button>
}
    {simpleFilter && <Button
    onClick={() => onClick ? onClick() : {}}
    sx={sharedStyles?.whiteBtn}> Filter</Button>}
    {/* </HorizontalBox> */}
</ResponsiveHorizontalBox>
  )
}

export default HeadingPlusFilter