import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS,BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend } from 'chart.js';
import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import 'chart.js/auto';
import { getInsuranceTrends, options } from './DashboardFunctions';
import HorizontalBox from '../../components/shared/HorizontalBox';
import {  patientsOverviewGraphEmptyData } from '../../utils/staticData';
import DateFilterModal from '../Dashboard/FilterModal';
import HeadingPlusFilter from '../../components/HeadingPlusFilter';
import {  mdyFormatDate } from '../../utils/DateFunctions';
import dayjs from 'dayjs';
import { sharedStyles } from '../../sharedStyles';

const TrendsChart = () => { 

    // ChartJS.register(LineElement, PointElement);
    ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
    // states
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
    const [selectedFilter, setSelectedFilter] = useState('')
    const [trendsLoading, setTrendsLoading] = useState(false)
    const [trendsData, setTrendsData] =  useState('')
    const [showModal, setShowModal] = useState(false)
    const [startDate, setStartDate]= useState(dayjs(new Date()))
    const [endDate, setEndDate] = useState(dayjs(new Date()))
    const [filter, setFilter] = useState(false)

    useEffect(()=>{
        getInsuranceTrends(selectedFilter,setTrendsLoading,setTrendsData)
    },[selectedFilter, filter])

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    // functions
    const dateFilterAction = () => {
        setSelectedFilter(`${mdyFormatDate(new Date(startDate),true)}-${mdyFormatDate(new Date(endDate),true)}`)
        setFilter(!filter)
    }

    return (
        <Box boxShadow={3} paddingY={2} paddingX={2} borderRadius={4}
            marginY={2}
        >
                <Box sx={sharedStyles?.bm1em}>
                <HeadingPlusFilter 
                simpleFilter
                onClick={()=>setShowModal(true)} heading={'Insurance Trends Overview'}/>
                </Box>
            {trendsLoading ? 
            <HorizontalBox sx={{...sharedStyles?.justifyCenter}}>
                <CircularProgress color="success" size={22} />
            </HorizontalBox>
            :
                <Bar
                options={options}
                    data={trendsData?.labels?.length ? trendsData : patientsOverviewGraphEmptyData}  height={isSmallScreen ? '300px' : '100px'} />}

            <DateFilterModal
                open={showModal}
                handleClose={() => setShowModal(false)}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                action={dateFilterAction}
                isLoading={trendsLoading}
                allowReset={selectedFilter}
                resetAction={()=> {
                    setSelectedFilter('')
                    setFilter(!filter)
                }}
            />
        </Box>
    );
};

export default TrendsChart;
