import { Typography } from '@mui/material'
import React from 'react'
import { styles } from './styles'

const BoldText = ({text}) => {
  return (
    <Typography sx={styles?.boldText}>{text}</Typography>
  )
}

export default BoldText