import React, { useState } from 'react';
import HorizontalHeadingPlusText from '../shared/HorizontalHeadingPlusText';
import { update_UpdatePatientInsuranceDetails } from '../../services/registration';
import { Button, Grid, Paper, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import styles from "../../css/discoveryDetails.module.css";

const EligibilityOutofnetwork = ({ data, patientInsuranceTabData, tabname, patientId }) => {
  const { leftBox, header, rightBox, button, buttonGrid } = styles; 
  const outOfNetworkDetails = [
    { name: 'Individual Deductible out-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualDeductibleOutNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualDeductibleOutNet || 'N/A' },
    { name: 'Individual Deductible Remaining out-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingOutNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingOutNet || 'N/A' },
    { name: 'Family Deductible out-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyDeductibleOutNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyDeductibleOutNet || 'N/A' },
    { name: 'Family Deductible Remaining out-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingOutNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingOutNet || 'N/A' },
    { name: 'Individual OOP out-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualOOP_OutNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualOOP_OutNet || 'N/A' },
    { name: 'Individual OOP Remaining out-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualOOPRemainingOutNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualOOPRemainingOutNet || 'N/A' },
    { name: 'Family OOP out-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyOOPOutNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyOOPOutNet || 'N/A' },
    { name: 'Family OOP Remaining out-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingOutNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingOutNet || 'N/A' }
  ];

  const [patientInsuranceDetailTabData, setPatientInsuranceDetailTabData] = useState(patientInsuranceTabData); 

  const handleSavePatientsDetails = async (tabName, patientId) => {
    try {
      const patientUpdatedData = await update_UpdatePatientInsuranceDetails(tabName, patientId);
      if (patientUpdatedData?.patientData) {
        setPatientInsuranceDetailTabData(patientUpdatedData?.patientData);
      } else {
        toast('Outofnetwork detail is empty');
      } 
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={leftBox}>
          <Typography variant="h5" align="center" className={header}>
            Payer
          </Typography>
          <Typography align="center" variant="h5" className={header}>
            {tabname === "detail"
              ? "Plan Details"
              : tabname === "coverage"
              ? "Coverage Details"
              : tabname === "DemographicInfo"
              ? "DemographicInfo Details"
              : tabname === "Innetwork"
              ? "In Network"
              : tabname === "Outofnetwork"
              ? "Out of Network"
              : tabname === "Speciality"
              ? "Speciality"
              : ""}
          </Typography>
          <hr/>
          {outOfNetworkDetails?.length && outOfNetworkDetails?.map((detail, index) => (
            <HorizontalHeadingPlusText heading={detail?.name} text={detail?.value} key={index} />
          ))}
          </Paper>
        </Grid>

        {/* Button */}
        <Grid item xs={12} md={2} className={buttonGrid}>
          <Button variant="outlined" className={button} onClick={()=>handleSavePatientsDetails(tabname, patientId)} >Sync with</Button>
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={rightBox}>
          <Typography variant="h5" align="center" className={header}>
            Practice
          </Typography>
          <Typography align="center" variant="h5" className={header}>
            {tabname === "detail"
              ? "Plan Details"
              : tabname === "coverage"
              ? "Coverage Details"
              : tabname === "DemographicInfo"
              ? "DemographicInfo Details"
              : tabname === "Innetwork"
              ? "In Network"
              : tabname === "Outofnetwork"
              ? "Out of Network"
              : tabname === "Speciality"
              ? "Speciality"
              : ""}
          </Typography>
          <hr />
            <HorizontalHeadingPlusText heading={'Individual Deductible out-Net'} text={patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.individualDeductibleOutNet?.value || patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.individualDeductibleOutNet|| 'N/A'} />
            <HorizontalHeadingPlusText heading={'Individual Deductible Remaining out-Net'} text={patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.individualDeductibleRemainingOutNet?.value || patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.individualDeductibleRemainingOutNet || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Family Deductible out-Net'} text={patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.familyDeductibleOutNet?.value || patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.familyDeductibleOutNet || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Family Deductible Remaining out-Net'} text={patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.familyDeductibleRemainingOutNet?.value || patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.familyDeductibleRemainingOutNet || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Individual OOP out-Net'} text={patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.individualOOP_OutNet?.value || patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.individualOOP_OutNet || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Individual OOP Remaining out-Net'} text={patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.individualOOPRemainingOutNet?.value || patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.individualOOPRemainingOutNet || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Family OOP out-Net'} text={patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.familyOOPOutNet?.value || patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.familyOOPOutNet || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Family OOP Remaining out-Net'} text={patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.familyOOPRemainingOutNet?.value || patientInsuranceDetailTabData?.outOfNetworkDetails
                      ?.familyOOPRemainingOutNet || 'N/A'} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default EligibilityOutofnetwork;
