import React from 'react'
import ResponsiveHorizontalBox from '../../components/shared/ResponsiveHorizontalBox'
import DiscoverySearchesProvider from './DiscoverySearchesProvider'
import InsuranceProviders from './InsuranceProviders'

const DiscoveryAndInsuranceProvider = () => {
  return (
      <ResponsiveHorizontalBox>
          <DiscoverySearchesProvider />
         <InsuranceProviders/>
      </ResponsiveHorizontalBox>
  )
}

export default DiscoveryAndInsuranceProvider