import { Box, Modal } from '@mui/material'
import React from 'react'
import { styles } from './styles'
import { ImCross } from "react-icons/im";
import { sharedStyles } from '../../../sharedStyles';

const ModalWrapper = ({open, handleClose,children}) => {
  return (
    <Modal
    open={open}
    onClose={handleClose}
  >
    <Box sx={styles?.modalWrapper}>
    <Box>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} sx={sharedStyles?.cursorPointer}>
      <ImCross onClick={handleClose}/>
      </Box>
    {children}
    </Box>
    </Box>
  </Modal>
  )
}

export default ModalWrapper