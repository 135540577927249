export function phoneNumberFormat(value) {
  const numericValue = value.replace(/\D/g, '');
  // Format the input as xxx-xxx-xxxx
  let formattedValue = '';
  if (numericValue.length <= 3) {
    formattedValue = numericValue;
  } else if (numericValue.length <= 6) {
    formattedValue = `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`;
  } else {
    formattedValue = `${numericValue.slice(0, 3)}-${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
  }
  return formattedValue;
}


export function postalCodeFormat(value) {
  const numericValue = value?.replace(/\D/g, ''); // Remove non-numeric characters
  // Format the input as xxx-xxx-xxxx
  let formattedValue = '';
  if (numericValue?.length <= 5) {
    formattedValue = numericValue;
  } else if (numericValue?.length <= 9) {
    formattedValue = `${numericValue?.slice(0, 5)}-${numericValue?.slice(5)}`;
  }
  return formattedValue;
}