import React from 'react'
import Discovery from './Discovery'
import { Box } from '@mui/material'
import { styles } from './styles'
import ScheduledTransactions from './ScheduledTransactions'

const DiscoveryTables = () => {
  return (
    <Box sx={styles?.discoveryTablesContainer}>
    <Discovery/>
    <ScheduledTransactions/>
    </Box>
  )
}

export default DiscoveryTables