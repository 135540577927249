import { ymdDateFormat } from "./DateFunctions";

export const defaultEligibilityUserDetails = () => ({
    transactionDate: ymdDateFormat(new Date()),
    payerCode: "",
    payerName: "",
    provider: {
        firstName: "",
        middleName: "",
        lastName: "",
        npi: "",
        TaxId: ""
    },
    subscriber: {
        firstName: "",
        dob: "",
        lastName: "",
        memberID: ""
    },
    dependent: "",
    isSubscriberPatient: "",
    doS_StartDate: "",
    doS_EndDate: "",
    PracticeTypeCode: "",
    referenceId: "",
    Location: "",
    IncludeHtmlResponse: ""
});

export const defaultEligibilityFormDetails = {

    PatientInsurancePayer: [],
    PatientFirstName: "",
    LastName: "",
    dateofservice: "",
    NPI: "",
    memberId: "",
    dob: "",
    location: "",
    transactionDate: ymdDateFormat(new Date())
};