import { get_GetPayerCodeByName } from '../../services/PayerService';
import GreenButton from '../../components/claims/GreenButton';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { styles } from './styles';
import { settingPayers } from './dataFormat';
import BoxComponent from '../../components/BoxComponent';
import { sharedStyles } from '../../sharedStyles';

const PayerDetail = () => {
    const [payer, setPayer] = useState([]);
    const location = useLocation();
    const { state } = location;



    async function getPayer() {
        try {
            const data = await get_GetPayerCodeByName(state?.data["Payer Name"]);
            setPayer(settingPayers(data[0]))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getPayer()
    }, [])

    return (
        <Paper sx={styles?.npiDetailContainer} elevation={3}>
            <Box sx={styles?.backButtonContainer}>
                <Typography fontWeight={'bold'} variant='h5'>Payer detail for ({payer[0]?.value}):</Typography>
                <Box>
                    <Button sx={[sharedStyles?.mr2, sharedStyles?.themeBtn]} variant='contained' size='small'>
                        Create Contract
                    </Button>
                    <Button to={'/userSettings'} state={{ tab: 3 }}  size='small' LinkComponent={Link} sx={sharedStyles?.whiteBtn}>
                        Back
                    </Button>
                </Box>
            </Box>
            <Box>
                {
                    <>
                        <Box>
                            <Grid container>
                                {
                                    payer?.length && payer?.map(({ label, value }, index) => (
                                        <Grid key={index} sx={sharedStyles?.mb2} item xs={12} sm={6} lg={4} xl={3}>
                                            <BoxComponent label={label} value={value || '----'} />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Box>
                    </>
                }
            </Box>

        </Paper>
    )
}

export default PayerDetail
