import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import GridComponentDentalEligibility from '../../css/GridComponentDentalEligibility';
import styles from '../../css/DentalSearchResultData.styles.css';
import { capitalizedFirstLetter } from '../../utils/reusableFunctions';

const DentalSearchresultdata = () => {
    const { headerContainer, patientName, marginTop2em } = styles;
    const pagename = "DentalSearchresultdata";
    const location = useLocation();
    const searchData = location.state?.searchData || [];

    return (
        <Box>
            <Box className={headerContainer}>
                <Box className={patientName}>
                    <p>{capitalizedFirstLetter(searchData?.patientData?.firstName + ' ' + searchData?.patientData?.lastName)}</p>
                </Box>
            </Box>

            <Box className={marginTop2em}>
            </Box>

            <GridComponentDentalEligibility
                pagename={pagename}
                searchData={searchData}
            />
        </Box>
    );
}

export default DentalSearchresultdata;
