import { Box, Button, Input } from '@mui/material';
import React, { useState } from 'react'



const Tabs = () => {
    const [active, setActive] = useState("Practice npi");

    const activateTab = (id) => {
        setActive(id)
    }

    return (
        <div style={{ width:'100%', paddingTop: 0, paddingLeft:'0' }} className='form-header-'>
            <div className='tabs' style={{  borderBottom:'1px solid #d1d1d1' }}>
                <Button onClick={(e) => activateTab(e.currentTarget.id)} id='Practice npi' variant={active === 'Practice npi' ? 'outlined' : 'text'} sx={{ padding: '0.5em 2em', color:'#000', borderColor:'black' }}>Pratice Npi</Button>
                <Button onClick={(e) => activateTab(e.currentTarget.id)} id='Date of Birth'  variant={active === 'Date of Birth' ? 'outlined' : 'text'} sx={{ borderRadius: 0, padding: '0.5em 2em', color:'#000', borderColor:'black' }}>Date of Birth</Button>
                <Button onClick={(e) => activateTab(e.currentTarget.id)} id='Firstname' variant={active === 'Firstname' ? 'outlined' : 'text'} sx={{ padding: '0.5em 2em', color:'#000', borderColor:'black' }}>Firstname</Button>
                <Button onClick={(e) => activateTab(e.currentTarget.id)} id='Lastname' variant={active === 'Lastname' ? 'outlined' : 'text'} sx={{ padding: '0.5em 2em', color:'#000', borderColor:'black' }}>Lastname</Button>
                <Button onClick={(e) => activateTab(e.currentTarget.id)} id='Date of Birth' variant={active === 'Date of Birth' ? 'outlined' : 'text'} sx={{ padding: '0.5em 2em', color:'#000', borderColor:'black' }}>Date of Birth</Button>
                <Button onClick={(e) => activateTab(e.currentTarget.id)} id='MRN/Patient Account #' variant={active === 'MRN/Patient Account #' ? 'outlined' : 'text'} sx={{ padding: '0.5em 2em', color:'#000', borderColor:'black' }}>MRN/Patient Account #</Button>
                <Button onClick={(e) => activateTab(e.currentTarget.id)} id='Member ID' variant={active === 'Member ID' ? 'outlined' : 'text'} sx={{ padding: '0.5em 2em', color:'#000', borderColor:'black' }}>Member ID</Button>
                <Button onClick={(e) => activateTab(e.currentTarget.id)} id='Annandale Family Practice' variant={active === 'Annandale Family Practice' ? 'outlined' : 'text'} sx={{ padding: '0.5em 2em', color:'#000', borderColor:'black' }}>Annandale Family Practice</Button>
                <Button onClick={(e) => activateTab(e.currentTarget.id)} id='Member City' variant={active === 'Member City' ? 'outlined' : 'text'} sx={{ padding: '0.5em 2em', color:'#000', borderColor:'black' }}>Member City</Button>
                <Button onClick={(e) => activateTab(e.currentTarget.id)} id='Member State' variant={active === 'Member State' ? 'outlined' : 'text'}  sx={{ padding: '0.5em 2em', color:'#000', borderColor:'black' }}>Member State</Button>
            </div>
            <div className='tab-content'>
                <Box sx={{ padding: '1em', width: '100%' }} id='0'>
                    <input type='text' value={``} style={{width: '100%', padding: '0.5em 0 0.5em 0.5em'}} placeholder={`Enter ${active}`} />
                    <Button variant='contained' color='success' sx={{marginTop: '1em'}}>Apply Changes</Button>
                </Box>
            </div>
        </div>
    )
}

export default Tabs