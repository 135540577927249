import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { bulk_Claims, get_GenerateEmptyClaimsCsvTemplateAsync, validateCsvFile, readCsvRecords } from "../../services/ClaimService.js";
import { DownloadCSV } from "../../utils/reusableFunctions.js"
import toast from "react-hot-toast";
import styles from "../../css/importPatient.module.css"
import { useNavigate } from 'react-router-dom';
import React, { useState } from "react";

const ImportClaims = () => {
  const pagename = "ImportClaims";
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();

  const generateCSV = async () => {
    try {
      const response = await get_GenerateEmptyClaimsCsvTemplateAsync();
      if (response?.csvFileByteArray) {
        DownloadCSV(response.csvFileByteArray, "Upload_Claim_Template.csv");
      } else {
        toast.error('Error while getting empty CSV file');
      }
    } catch (error) {
      console.error('Error generating CSV file:', error);
      toast.error('Failed to generate CSV template.');
    }
  };

  const handleChange = async (e) => {
    const file = e?.target?.files?.[0];
    if (!file) {
      toast.error('No file selected');
      return;
    }

    setSelectedFile(file);
    const formData = new FormData();
    formData.append("csvFile", file);

    try {
      const validationResult = await validateCsvFile(formData);
      if (validationResult === "CSV headers are valid.") {
        toast.success('File uploaded successfully');
      } else {
        toast.error(`File upload failed: ${validationResult?.message}`);
      }
    } catch (error) {
      console.error('Error validating CSV file:', error);
      toast.error('File validation failed');
    }
  };

  const handlePreviewClick = async () => {
    if (!selectedFile) {
      toast.error('Please upload a CSV file first.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append("csvFile", selectedFile);

      const previewResult = await readCsvRecords(formData);
      if (previewResult?.isSuccessful) {
        toast.success('Records read and validated successfully');
        navigate('/batchclaims', { state: { searchData: previewResult } });
      } else {
        toast.error(`Error reading records: ${previewResult?.message}`);
      }
    } catch (error) {
      console.error('Error reading CSV records:', error);
      toast.error('Failed to read CSV records');
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={6}>
          <p>* Upload a new CSV file</p>
        </Grid>
        <Grid item xs={12} sm={6} lg={6} textAlign="right">
          <Button
            variant='contained'
            color='success'
            onClick={generateCSV}
            className={styles?.downloadCsvTemplate}
          >
            Download CSV Template
          </Button>
        </Grid>
      </Grid>
      {/* <p>* Maximum Size of the file should be 20MB</p> */}
      <Box className={styles?.uploadCsv}>
        <button className={styles?.uploadCsvButton}>
          <CloudUploadIcon />
          <span>Upload CSV</span>
        </button>
        <input
          type="file"
          onChange={handleChange}
          className={styles?.inputTag}
        />
      </Box>
      <Box className={styles?.previewButtonContainer}>
        <Grid item xs={12} sm={6} lg={6} textAlign="right">
          <Button
            variant='contained'
            color='success'
            className={styles?.downloadCsvTemplate}
            onClick={handlePreviewClick}
          >
            PREVIEW
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default ImportClaims;