import { Box } from '@mui/material'
import React from 'react'

const ShadowBoxContainer = ({
    children,
    width,
    border,
    boxShadow,
    borderRadius,
    paddingX,
    paddingY,
    sx
}) => {
    return (
        <Box sx={{ width: width ?? '100%', boxShadow: boxShadow ?? 3, borderRadius: borderRadius ?? '10px', paddingX: paddingX ?? 2, paddingY: paddingY ?? 2, ...sx }}>
            {children}
        </Box>
    )
}

export default ShadowBoxContainer