import React, { useEffect, useState } from 'react';
import HorizontalHeadingPlusText from '../shared/HorizontalHeadingPlusText';
import { update_UpdatePatientInsuranceDetails } from '../../services/registration';
import { Button, Grid, Paper, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import styles from "../../css/discoveryDetails.module.css";
import { formatOutOfNetworkDetails, getTabNameLabel } from '../../Pages/Discovery/DiscoveryFunctions';
import SyncButton from './SyncButton';

const Outofnetwork = ({ data, patientInsuranceTabData, tabname, patientId }) => {
  const { leftBox, header, rightBox, button, buttonGrid } = styles; 
  const outOfNetworkDetails = [
    { name: 'Individual Deductible out-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualDeductibleOutNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualDeductibleOutNet || 'N/A' },
    { name: 'Individual Deductible Remaining out-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingOutNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingOutNet || 'N/A' },
    { name: 'Family Deductible out-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyDeductibleOutNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyDeductibleOutNet || 'N/A' },
    { name: 'Family Deductible Remaining out-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingOutNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingOutNet || 'N/A' },
    { name: 'Individual OOP out-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualOOP_OutNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualOOP_OutNet || 'N/A' },
    { name: 'Individual OOP Remaining out-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualOOPRemainingOutNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualOOPRemainingOutNet || 'N/A' },
    { name: 'Family OOP out-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyOOPOutNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyOOPOutNet || 'N/A' },
    { name: 'Family OOP Remaining out-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingOutNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingOutNet || 'N/A' }
  ];

  const [patientInsuranceDetailTabData, setPatientInsuranceDetailTabData] = useState([]); 
  const [isLoading, setIsLoading] = useState(false)

  useEffect(()=>{
    setPatientInsuranceDetailTabData(formatOutOfNetworkDetails(patientInsuranceTabData))
  },[patientInsuranceTabData])

  const handleSavePatientsDetails = async (tabName, patientId) => {
    try {
      setIsLoading(true)
      const patientUpdatedData = await update_UpdatePatientInsuranceDetails(tabName, patientId);
      if (patientUpdatedData?.patientData) {
        setPatientInsuranceDetailTabData(formatOutOfNetworkDetails(patientUpdatedData?.patientData?.outOfNetworkDetails));
      } else {
        toast('Outofnetwork detail is empty');
      } 
    } catch (error) {
      console.error(error);
    }
    finally{
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={leftBox}>
          <Typography variant="h5" align="center" className={header}>
            Payer
          </Typography>
          <Typography align="center" variant="h5" className={header}>
          {getTabNameLabel(tabname)}
          </Typography>
          <hr/>
          {outOfNetworkDetails?.length && outOfNetworkDetails?.map((detail, index) => (
            <HorizontalHeadingPlusText heading={detail?.name} text={detail?.value} key={index} />
          ))}
          </Paper>
        </Grid>

        {/* Button */}
        <Grid item xs={12} md={2} className={buttonGrid}>
          <SyncButton onClick={()=>handleSavePatientsDetails(tabname, patientId)} isLoading={isLoading}/> 
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={rightBox}>
          <Typography variant="h5" align="center" className={header}>
            Practice
          </Typography>
          <Typography align="center" variant="h5" className={header}>
          {getTabNameLabel(tabname)}
          </Typography>
          <hr />
          {
             patientInsuranceDetailTabData?.length && patientInsuranceDetailTabData?.map(({heading,text},index)=>{
                return(
                  <HorizontalHeadingPlusText heading={heading} text={text|| 'N/A'} key={index} /> 
                )
              })
            }  
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Outofnetwork;
