import React, { useEffect, useState } from 'react'
import { getNpiDetails } from '../../services/getNpiData';
import { getLocalData } from '../../utils/localStorageFunctions';
import { Box, Grid, Typography } from '@mui/material';
import BoxComponent from '../../components/BoxComponent';
import ErrorBoundary from '../../components/Errors/ErrorBoundary';
import { styles } from './styles';
import { settingMailingAddress, settingNpiFields, settingPrimaryPracticeAddressFields, settingSecondaryAddress, settingTaxonomyDetails } from './dataFormat';


const NpiInfo = () => {
    const [npiDetailsFields, setNpiDetailsFields] = useState([]);
    const [primaryPracticeAddress, setPrimaryPracticeAddress] = useState([]);
    const [mailingAddress, setMailingAddress] = useState([]);

    const [secondaryAddress, setSecondaryAddress] = useState([])
    const [details, setDetails] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const npiInfo = getLocalData("selectedNpi");
    const { npiNumber } = npiInfo;


    useEffect(() => {

        if (details?.npiDetails) {
            setNpiDetailsFields(settingNpiFields(details?.npiDetails))
        }

        if (details?.primaryAddress?.primaryPracticeAddress) {
            setPrimaryPracticeAddress(settingPrimaryPracticeAddressFields(details?.primaryAddress?.primaryPracticeAddress));
        }

        if (details?.mailAddress?.mailingAddress) {
            setMailingAddress(settingMailingAddress(details?.mailAddress?.mailingAddress));
        }

        if (details?.secondaryAddress?.secondaryPracticeAddresses) {
            setSecondaryAddress(settingSecondaryAddress(details?.secondaryAddress?.secondaryPracticeAddresses));
        }
    }, [details])




    const getData = async () => {
        setIsLoading(true)
        try {
            const response = await getNpiDetails(npiNumber);
            if (response?.data?.isSuccessful) {
                const data = response?.data?.npiData;
                const { id, npi, providerFirstName, providerLastName, providerOrganizationName, gender, certificationDate, lastUpdated, enumerationDate, npiType, soleProprietor, status, mailingAddress, primaryPracticeAddress, secondaryPracticeAddresses, otherIdentifiers, taxonomy } = data;
                const npiDetails = { id, npi, providerFirstName, providerLastName, providerOrganizationName, gender, certificationDate, lastUpdated, enumerationDate, npiType, soleProprietor, status }
                const mailAddress = { mailingAddress };
                const primaryAddress = { primaryPracticeAddress };
                const secondaryAddress = { secondaryPracticeAddresses };
                const taxonomyDetails = { taxonomy }
                const obj = { npiDetails, mailAddress, primaryAddress, secondaryAddress, taxonomyDetails, otherIdentifiers };
                setDetails(obj);
            } else {
                setDetails(null);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        getData()
    }, [npiNumber]);

    return (
        <div>
            {
                !details ?
                    (
                        <ErrorBoundary message={'Npi Details Not Found!'} />
                    )
                    :
                    <Grid container spacing={10}>
                        <Grid item xs={12} sm={10} lg={6} xl={4}>
                            <Box sx={styles?.cardStyles}>
                                <div style={styles?.innerCardHeader}>
                                    <Typography variant='h6'>NPI Data</Typography>
                                </div>
                                {npiDetailsFields?.map(({ label, value }) => (
                                    <BoxComponent
                                        key={label}
                                        isLoading={isLoading}
                                        label={label}
                                        value={value || '----'}
                                    />
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={6} xl={4}>
                            <Box sx={styles?.cardStyles}>
                                <div style={styles?.innerCardHeader}>
                                    <Typography variant='h6'>Taxonomy</Typography>
                                </div>
                                {
                                    <>
                                        <div>
                                            {details?.taxonomyDetails?.taxonomy?.map(({ licenseNumber, primayTaxonomy, selectedTaxonomy, state }, index) => (
                                                <>
                                                    <BoxComponent
                                                        key={index}
                                                        isLoading={isLoading}
                                                        label={'License Number: '}
                                                        value={licenseNumber || '----'}
                                                    />
                                                    <BoxComponent
                                                        key={index}
                                                        isLoading={isLoading}
                                                        label={'Primay Taxonomy: '}
                                                        value={primayTaxonomy || '----'}
                                                    />
                                                    <BoxComponent
                                                        key={index}
                                                        isLoading={isLoading}
                                                        label={'Selected Taxonomy: '}
                                                        value={selectedTaxonomy || '----'}
                                                    />
                                                    <BoxComponent
                                                        key={index}
                                                        isLoading={isLoading}
                                                        label={'State: '}
                                                        value={state || '----'}
                                                    />
                                                    <hr />
                                                </>
                                            ))}
                                        </div>

                                    </>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={6} xl={4}>
                            <Box sx={styles?.cardStyles}>
                                <div style={styles?.innerCardHeader}>
                                    <Typography variant='h6'>Mailing Information</Typography>
                                </div>
                                {mailingAddress?.map(({ label, value }) => (
                                    <BoxComponent
                                        key={label}
                                        isLoading={isLoading}
                                        label={label}
                                        value={value || '----'}
                                    />
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={6} xl={4}>
                            <Box sx={styles?.cardStyles}>
                                <div style={styles?.innerCardHeader}>
                                    <Typography variant='h6'>Primary Mailing Information</Typography>
                                </div>
                                {primaryPracticeAddress?.map(({ label, value }) => (
                                    <BoxComponent
                                        key={label}
                                        isLoading={isLoading}
                                        label={label}
                                        value={value || '----'}
                                    />
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={6} xl={4}>
                            <Box sx={styles?.cardStyles}>
                                <div style={styles?.innerCardHeader}>
                                    <Typography variant='h6'> Secondary Mailing Information</Typography>
                                </div>
                                {
                                    details?.secondaryAddress?.secondaryPracticeAddresses?.length === 0 ?
                                        "No Data Found"
                                        :
                                        details?.secondaryAddress?.secondaryPracticeAddresses?.map((item, index) => (
                                            <>
                                                {secondaryAddress?.map(({ label, key }) => (
                                                    <BoxComponent
                                                        key={index}
                                                        isLoading={isLoading}
                                                        label={label}
                                                        value={item?.[key] || '----'}
                                                    />
                                                ))}
                                            </>
                                        ))
                                }
                            </Box>
                        </Grid>
                    </Grid>
            }
        </div>
    )
};



export default NpiInfo
