import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { styles } from "./style";

export const payerGridOptions = {
  // Enable pagination
  pagination: true,
  // Set the number of rows per page
  paginationPageSize: 10, // Adjust as needed
  // Optional: Specify the layout of pagination components
  domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
  overlayNoRowsTemplate: `
  <span class="ag-overlay-loading-center">
      Loading
  </span>
`
  // Other grid options...
};


export const veiwPayer = ({ data }) => {

  return (
    <div className="flex">
      <Link to={'/payerDetail'} state={{ data: data }} variant='contained' color='success' size='small' style={styles?.payerTableLink}>
        View Details
      </Link>
    </div>
  )
};