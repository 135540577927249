import React, { useEffect, useState } from 'react'
import MyTabs from '../../components/tabs/MyTabs'
import PatientManagement from './PatientManagement'
import AddPatient from './AddPatient'
import { Box, Button } from '@mui/material'
import SyncPatient from './SyncPatient'
import ImportPatient from './ImportPatient'
import { useSearchParams } from 'react-router-dom'
import PatientDashboard from './PatientDashboard'


const Patients = () => {
    const [searchParams] = useSearchParams();
    const preSelectedTabParam = searchParams.get('preSelectedTab');
    const [preSelectedTab] = useState(preSelectedTabParam ?? null);
    const tabs =
        [
            {
                label: "Dashboard", value: <PatientDashboard />,
                button: 'Add Patient', changeTab: 2
            },
            {
                label: "Patient Management", value: <PatientManagement />,
                button: 'Add Patient', changeTab: 2
            },
            {
                label: "Add Patient", value: <AddPatient />, changeTab: 0, button: 'Back'
            },
            // {
            //     label: 'Appointments', value: <AppointmentCalendar />, changeTab: 2, button: 'Add Patient',
            // },
            {
                label: "Import Patient", value: <ImportPatient />, changeTab: 0, button: 'Back'
            },
            {
                label: "Patient Sync", value: <SyncPatient />, changeTab: 0, button: 'Back'
            }
            
        ]

    return (
        <section className='patients'>
            <Box sx={{
                boxShadow: '0 0 2px 0 #919EAB',
                borderRadius: '12px'
            }}>
                <MyTabs tabs={tabs} preSelectedTab={Number(preSelectedTab)} />
            </Box>
        </section>
    )
}

export default Patients