import { colors } from "./utils/colors";

export const sharedStyles = {
    cursorPointer: { cursor: 'pointer', marginRight: '2em' },
    bm1em: { marginBottom: '1em' },
    horizontalCenteredFlex: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    filterButton: { color: colors?.themeGreen, border: `1px solid ${colors?.themeGreen}` },
    themeBtn: { background: colors?.themeGreen, color: colors?.white, '&:hover': { background: colors?.themeGreen }, borderRadius: '10px' },
    fullWidth: { width: '100%' },
    justifyBetween: { justifyContent: 'space-between' },
    justifyCenter: { justifyContent: 'center' },
    mt5px: { marginTop: '5px' },
    mv10px: { marginTop: '10px', marginBottom: '10px' },
    mb2: {
        marginBottom: '2em'
    },
    pshalf: {
        paddingLeft: '0.5em'
    },
    h500: {
        height: '500px'
    },
    primaryButtonStyles: { backgroundColor: 'green', border: 'none', color: 'white', fontSize: 'small' },
    mt2em: {
        marginTop: '2em'
    },
    tabWrapper: {
        boxShadow: '0 0 2px 0 #919EAB',
        borderRadius: '12px'
    },
    mr_3: {
        marginRight: -3
    },
    mr1: {
        marginRight: 1
    },
    ml5: {
        marginLeft: 5
    },
    mr1: {
        marginRight: 1
    },
    mr2: {
        marginRight: '1.5em'
    },
    whiteBtn: {
        background: colors?.white, color: colors?.black, boxShadow: 0, border: `1px solid ${colors?.border}`, '&:hover': {
            backgroundColor: colors?.surface,
            color: colors?.black,
        },
    },
    pv10: {
        paddingBottom: 10,
        paddingTop: 10
    },
    resetButton: {
        color: colors?.white,
        backgroundColor: colors?.red,
        marginRight: 3,
        '&:hover': {
            backgroundColor: colors?.red,
            color: colors?.white,
        },
    },
    resetButtonOnly: {
        color: colors?.white,
        backgroundColor: colors?.red,
        '&:hover': {
            backgroundColor: colors?.red,
            color: colors?.white,
        },
    },
    justifyEnd: {
        justifyContent: 'flex-end'
    },
    cursorPointerOnly: { cursor: 'pointer' },
    gap2: { gap: 2 },
    displayFlex: { display: 'flex' },
    show: { display: 'block' },
    hide: { display: 'none' },
    flex: { display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1em' },
    whiteBtnDisabled: {
        background: colors?.white, marginTop: '1em', color: colors?.black, border: `1px solid ${colors?.themeDarkBlue}`, boxShadow: 0, border: `1px solid ${colors?.black}`, cursor: 'not-allowed'
    },
    justifyEnd: { justifyContent: 'end' }
};
