import { Box } from '@mui/material';
import React from 'react';

const CircleWrapper = ({
    children,
    size,
    backgroundColor,
    border,
    boxShadow,
    borderRadius,
    pointer,
    ...sx
}) => {
    return (
        <Box
            sx={{
                width: size ?? 40,
                height: size ?? 40,
                backgroundColor: backgroundColor || '#edeaf1',
                border: border || 'none',
                boxShadow: boxShadow || 3,
                borderRadius: borderRadius || '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor:pointer ? 'pointer' : 'default',
                ...sx
            }}
        >
            {children}
        </Box>
    );
};

export default CircleWrapper;
