import { Badge, Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { styles } from './style';
import { sharedStyles } from '../../sharedStyles';
import { staticNotifications, unReadNotifications, unReadNotificationsMessage } from '../../utils/staticData';

const Bar = ({ notification, onRead, time }) => {

    return (
        <Box sx={styles?.bar}>
            <Box sx={styles?.barInnerBox}>
                <Box sx={styles?.barInnerWrapper}>
                    <NotificationsIcon />
                    <Typography>{notification}</Typography>
                </Box>
                <Button onClick={() => onRead(notification)}>
                    <CloseOutlinedIcon color='black' />
                </Button>
            </Box>
            <Typography variant='caption' sx={sharedStyles?.pshalf}>{time}</Typography>
        </Box>
    )
}



const Notification = () => {
    const [nots, setNots] = useState(staticNotifications);

    const handleRead = (item) => {
        setNots(prevNots => prevNots?.filter(not => not?.notification !== item));
    };


    return (
        <div style={styles?.outerWrapper}>
            {nots?.length === 0 ? (
                <Typography variant='h3'>{unReadNotificationsMessage}</Typography>
            ) : (
                <Box sx={styles?.badgeWrapper}>
                    <Badge badgeContent={nots?.length} color='warning'>
                        <Typography variant='h3' sx={styles?.notHeading}>{unReadNotifications}</Typography>
                    </Badge>
                </Box>
            )}
            {nots?.length && nots?.map(item => (
                <Bar
                    key={item?.notification}
                    notification={item?.notification}
                    time={item?.time}
                    onRead={handleRead}
                />
            ))}
        </div>

    )
}

export default Notification;
