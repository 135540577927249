import React from "react";
import Registration from './Registration'

const Auth = () => {

  return (
    <section className='auth'>
      <Registration />
    </section>
  )
}

export default Auth