import React from 'react'
import { Box } from '@mui/material'
import { styles } from './styles'
import Claim from './Claim'
import ClaimScheduledTransactions from './ClaimScheduledTransactions'

const ClaimTables = () => {
  return (
    <Box sx={styles?.claimTablesContainer}>
        <Claim />
        <ClaimScheduledTransactions/>
    </Box>
  )
}

export default ClaimTables