import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

const DelayedWrapper = ({ children,time }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, time ? time * 1000 : 500);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <CircularProgress color="inherit" size={22}/>
  }

  return <>{children}</>;
};

export default DelayedWrapper;
