import { Box, Button,  Typography } from '@mui/material'
import React from 'react'
import PatientsClaimsChart from './PatientsClaimsChart';
import HorizontalBox from '../../components/shared/HorizontalBox';
import PatientClaimsInsightCards from './PatientClaimsInsightCards';
import { styles } from './styles';
import PayerDetails from './PayerDetails';
import ResponsiveHorizontalBox from '../../components/shared/ResponsiveHorizontalBox';

const PatientsClaimsInsight = () => {
    return (
        <div>
            <HorizontalBox sx={{ justifyContent: 'space-between' }}>
                <Typography variant="h5">
                    Patients Claims Insight
                </Typography>
                 <Button
                sx={styles?.filterButton}> Filter</Button>
            </HorizontalBox>
            <ResponsiveHorizontalBox rowFromLg>
            <Box  width={{ xs: '100%', lg: '50%' }}
            paddingRight={{xs:2,lg:0}} 
            >
            <PatientClaimsInsightCards />
            </Box>
            <Box paddingLeft={{xs:2,lg:0}} 
             width={{ xs: '100%', lg: '50%' }}
            >
            <PayerDetails/>
            </Box>
            </ResponsiveHorizontalBox>
            <Box boxShadow={3} padding={2} borderRadius={4} marginTop={2} marginX={2}>
                <Typography variant="h5" marginBottom={2}>
                    Patients Claims Chart
                </Typography>
                <PatientsClaimsChart />
            </Box>
        </div >
    )
}

export default PatientsClaimsInsight