import React from 'react'
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import GridComponentClaims from '../../css/GridComponentClaims';
import  "../../css/ClaimSearchStyles.css";
const ClaimSearchresultdata = () => {

    const pagename = "ClaimSearchresultdata";
    const location = useLocation();
    const {searchData} = location.state || []; 
    return (
        <Box>
            <Box className="flexBox">
                <Box className="widthBox">
                    <p>{searchData?.patientData?.firstName} {searchData?.patientData?.lastName}</p>
                </Box>
            </Box>
    <Box className="marginTopBox"></Box>
    <GridComponentClaims
        pagename={pagename}
        searchData={searchData}
    />
</Box>
    )
}
export default ClaimSearchresultdata