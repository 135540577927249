import * as React from 'react';
import { Link } from 'react-router-dom';

export default function SmSidebar({ openDrawer, onOpen }) {
    const [open, setOpen] = React.useState(false);
    const navRef = React.useRef();
    
 

    return (
        <div>
            <nav ref={navRef} className={`${openDrawer ? 'open' : 'op-0'} sm-sidebar`}>
                <ul>
                    <li>
                        <Link className={`${openDrawer ? 'op' : 'op-0'}`}>Dashboard</Link>
                    </li>

                    <div>
                        <li>
                            <Link className={`${openDrawer ? 'op' : 'op-0'}`}>Insurance</Link>
                        </li>
                        <ul>
                            <li>
                                <Link className={`${openDrawer ? 'op' : 'op-0'}`}>First</Link>
                            </li>
                            <li>
                                <Link className={`${openDrawer ? 'op' : 'op-0'}`}>Second</Link>
                            </li>
                        </ul>
                    </div>
                </ul>
            </nav>
        </div>
    )
}
