import React, { useState } from 'react'
import HorizontalBox from '../../../components/shared/HorizontalBox'
import { SlActionRedo } from "react-icons/sl";
import CircleWrapper from '../../../components/shared/CircleWrapper';
import { TiCancelOutline } from "react-icons/ti";
import { styles } from '../styles';
import { colors } from '../../../utils/colors';
import { CircularProgress, Tooltip } from '@mui/material';
import { FaPencil } from "react-icons/fa6";
import { smallLettersStr } from '../../../utils/reusableFunctions';
import toast from 'react-hot-toast';
import { taskAlreadyCancelledStr } from '../../../utils/staticData';


const ScheduledActions = (params,action,cancelTask,isChecking,checkTaskNow,setTaskToBeModified,taskToBeModified,isCancelling ) => {
    const {id,status} = params?.data

    const handleCancelTask = () => {
        setTaskToBeModified(id)
        cancelTask()
    }
    const [actions] = useState([
        {
            icon: <FaPencil fill={colors?.white}/>,
            toolTip: 'Edit',
            action
        },
        {
            icon: isChecking  && id === taskToBeModified ?  <CircularProgress color='inherit' size={15}/> : <SlActionRedo fill={colors?.white} />,
            toolTip: 'Check Now',
            action:()=>{
                setTaskToBeModified(id)
                checkTaskNow()}
        },
        {
            icon: isCancelling && id === taskToBeModified ? <CircularProgress color='inherit' size={15}/> : <TiCancelOutline fill={colors?.white} />,
            toolTip: 'Cancel',
            action:() => {
               smallLettersStr(status) === 'cancelled' ? toast?.error(taskAlreadyCancelledStr) :  
               handleCancelTask()
                
            }
        }
    ])
    return (
        <HorizontalBox sx={styles?.scheduledActionsContainer}>
            {
                actions?.map(({ icon, toolTip,action }, index) => {
                    return (
                        <Tooltip title={toolTip} key={index} onClick={action}>
                            <div>
                                <CircleWrapper size={30} backgroundColor={toolTip !== 'Cancel'  ? colors?.green : colors?.danger} pointer >
                                    {icon}
                                </CircleWrapper>
                            </div>
                        </Tooltip>
                    )
                })
            }
        </HorizontalBox>
    )
}

export default ScheduledActions