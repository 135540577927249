import { Box, Typography } from '@mui/material'
import React from 'react'
import CircleWrapper from '../shared/CircleWrapper'
import { colors } from '../../utils/colors'
import { MdOutlineCreateNewFolder, MdOutlineNewLabel } from 'react-icons/md'
import { GrDocumentMissing } from 'react-icons/gr'
import { styles } from './styles';
import './styles.css'

const DashboardCard = ({ heading, count,index,countColor }) => {
    return (
        <Box item xs={12} md={4}
            width={'100%'}
            paddingX={{ xs: 1, sm: 2 }}
        >
            <Box boxShadow={3} paddingY={2} paddingX={2} borderRadius={4}
                marginY={2}
            >
                <CircleWrapper >
                    {index === 0 ? <MdOutlineCreateNewFolder fill={colors?.themeGreen} /> : index === 1 ? <GrDocumentMissing stroke={colors?.themeGreen} /> : <MdOutlineNewLabel fill={colors?.themeGreen} />}
                </CircleWrapper>
                <Typography variant="body1" className='card-text'>
                    {heading}
                </Typography>
                <Typography variant="body1" sx={styles.cardValue} color={countColor ?? colors?.themeGreen}>
                    {count}
                </Typography>
            </Box>
        </Box>
    )
}

export default DashboardCard