import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, Card, CardContent, Typography, Divider, Grid, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HorizontalHeadingPlusText from '../shared/HorizontalHeadingPlusText';

const EligibilitySpeciality = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getFilteredDetails = (eligibilityDetails, type) => {
    if (!eligibilityDetails?.length) return [];
    return eligibilityDetails.filter(detail => {
      if (type === 'inPlan') {
        return detail?.planNetworkIndicator === 'Yes';
      } else if (type === 'outPlan') {
        return detail?.planNetworkIndicator === 'No';
      } else if (type === 'both') {
        return detail?.planNetworkIndicator === 'Both';
      }
      return false;
    });
  };

  const renderDetails = (details) => {
    if (!details?.length) return null;
    return details.map((detail, index) => (
      <Card key={index} sx={{ margin: '1rem 0', padding: '1rem', boxShadow: 3 }}>
        <CardContent>
          <HorizontalHeadingPlusText
            heading="Eligibility or Benefit:"
            text={detail?.eligibilityOrBenefit || 'N/A'}
          />
          <Divider />
          <Box sx={{ marginTop: '0.5rem' }}>
            <HorizontalHeadingPlusText
              heading="Coverage Level:"
              text={detail?.coverageLevel || 'N/A'}
            />
            <HorizontalHeadingPlusText
              heading="Monetary Amount:"
              text={detail?.monetaryAmount || 'N/A'}
            />
            <HorizontalHeadingPlusText
              heading="Percent:"
              text={detail?.percent || 'N/A'}
            />
            <HorizontalHeadingPlusText
              heading="Plan Coverage Description:"
              text={detail?.planCoverageDescription || 'N/A'}
            />
            {detail?.message?.length > 0 && (
              <HorizontalHeadingPlusText
                heading="Message:"
                text={detail.message.join(', ')}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    ));
  };

  return (
    <div>
      {data.serviceDetails?.length > 0 && data.serviceDetails.map((service, idx) => {
        const inPlanDetails = getFilteredDetails(service?.eligibilityDetails, 'inPlan');
        const outPlanDetails = getFilteredDetails(service?.eligibilityDetails, 'outPlan');
        const bothPlanDetails = getFilteredDetails(service?.eligibilityDetails, 'both');

        return (
          <Accordion key={idx} sx={{ margin: '1rem 0' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Service Type: {service?.serviceName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ marginBottom: '1rem' }}>
                    <strong>Service Type:</strong> {service?.serviceName || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    sx={{ minHeight: '32px', fontSize: '0.75rem' }}
                  >
                    <Tab label="In Plan" sx={{ minHeight: '32px', fontSize: '0.75rem' }} />
                    <Tab label="Out of Plan" sx={{ minHeight: '32px', fontSize: '0.75rem' }} />
                    <Tab label="Both" sx={{ minHeight: '32px', fontSize: '0.75rem' }} />
                  </Tabs>
                </Grid>
                <Grid item xs={12}>
                  {activeTab === 0 && (
                    <Box>
                      {inPlanDetails?.length > 0 ? renderDetails(inPlanDetails) : <Typography>No In Plan Details</Typography>}
                    </Box>
                  )}
                  {activeTab === 1 && (
                    <Box>
                      {outPlanDetails?.length > 0 ? renderDetails(outPlanDetails) : <Typography>No Out of Plan Details</Typography>}
                    </Box>
                  )}
                  {activeTab === 2 && (
                    <Box>
                      {bothPlanDetails?.length > 0 ? renderDetails(bothPlanDetails) : <Typography>No In-and-Out Plan Details</Typography>}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default EligibilitySpeciality;
