import React, { useEffect, useState } from 'react'
import DashboardCard from '../../components/DashboardCard/DashboardCard'
import { getNumberOfInsuranceSearches } from './DashboardFunctions'
import PageLoader from '../../components/loader/PageLoader'

const DiscoverySearchesProvider = () => {
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(false)
    useEffect(()=>{
        getNumberOfInsuranceSearches(setData,setLoading)
    },[])
  return (
   loading ? <PageLoader height={'20vh'}/> : <DashboardCard heading={'Total Number of Insurance Discovery Searches'} count={data} index={0}/>
  )
}

export default DiscoverySearchesProvider