import React, { useState } from 'react'
import { Box,} from '@mui/material';
import GridComponent from '../../css/GridComponent';
import TableHeader from '../../components/TableHeader';
import FieldEditModal from '../../components/modals/FieldEditModal';
import { post_Patient } from '../../services/addPatient';
import { getLocalData } from '../../utils/localStorageFunctions';
import { ymdDateFormat } from '../../utils/DateFunctions';
import PageLoader from '../../components/loader/PageLoader';
import toast from 'react-hot-toast';
import { succesfulPatientUpdationStr } from '../../utils/staticData';
import { failedGetApi, getRequestNpi } from '../../utils/reusableFunctions';
import BoldText from '../../components/Texts/BoldText';
import InsuranceDiscoveryGrid from './InsuranceDiscoveryGrid';


const Discovery = () => {
  const [pagename,setPageName] = useState("Discovery");
  const [filterPatient, setFilterPatient] = useState("");
  const [showModal, setShowModal] = useState(false)
  const [newPhone, setNewPhone] = useState('')
  const [selectedPatientData, setSelectedPatientData] = useState({})
  const [defaultNewPhone, setDefaultNewPhone] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const {userId} = getLocalData('user')
  const [isEditing, setIsEditing] = useState(false)


  const editPatient = async () => {
    try {
      const { appointments: appointmentDate, email, groupNumber, memberId, dob: patientDOB, firstName: patientFirstName
        , lastName: patientLastName, id: patientId, policyHolderRelationship, Requestid, payerName: insuranceName } = selectedPatientData
    
      const payload = {
        appointmentDate: appointmentDate?.length ? appointmentDate : null,
        email: email || '',
        groupNumber: groupNumber || '',
        memberId: memberId || '',
        patientDOB: ymdDateFormat(patientDOB) || '',
        patientFirstName: patientFirstName || '',
        patientLastName: patientLastName || '',
        patientId: patientId || '',
        policyHolderRelationship: policyHolderRelationship || '',
        requestNpi: getRequestNpi(),
        insuranceName: insuranceName || null,
        phoneNumber: newPhone,
        createdBy:userId,
        updatedBy:userId,
      }


      setIsEditing(true)
      const updatePatient = await post_Patient(payload,true)
      if (updatePatient?.data?.isSuccessful) {
        toast?.success(succesfulPatientUpdationStr)
        setPageName(pagename === 'Discovery' ? 'DiscoveryPage' : 'Discovery')
        
      }
    }
    catch (e) {
      failedGetApi(e)
    }
    finally{
      handleClose()
      setIsEditing(false)
    }
  }

  const handleClose = () => {
    setShowModal(false)
    setNewPhone('')
    setSelectedPatientData({})
    setDefaultNewPhone('')
  }



  return (
    <Box>
      <BoldText text={'Recent Scheduled'} />
      <Box>
        <TableHeader setFilterPatient={setFilterPatient} />
      </Box>
      <Box sx={{ marginTop: '2em' }}>
        <InsuranceDiscoveryGrid openEditor={(value)=>{
          setShowModal(true)
          setSelectedPatientData(value)
          setNewPhone(value?.phone)
          setDefaultNewPhone(value?.phone)
          }}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          pagename={pagename}
          />
      </Box>
      <FieldEditModal
      open={showModal}
      handleClose={handleClose}
      fiedlName={'Phone'}
      action={editPatient}
      value={newPhone}
      handleChange={(e)=>setNewPhone(e?.target?.value)}
      defaultValue={defaultNewPhone}
      isLoading={isEditing}
      fieldType={'number'}
      />
    </Box>
  )
}
export default Discovery