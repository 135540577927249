import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material';
import PatientDashboardCards from './PatientDashboardCards';
import HeadingPlusFilter from '../../components/HeadingPlusFilter';
import HorizontalBox from '../../components/shared/HorizontalBox';
import { sharedStyles } from '../../sharedStyles';
import { styles } from './styles';
import DateFilterModal from '../Dashboard/FilterModal';
import { dataLabels, dataSets } from '../../utils/DataFormatters/PatientsOverviewGraphDataFormatters';
import { getPatientOverviewChartDetails } from '../../services/addPatient';
import { patientsOverviewGraphData } from '../../utils/staticData';
import { ToastBar } from 'react-hot-toast';
import { formatDateMDY, formatDateToReadable, formatDateYMD } from '../../utils/DateFunctions';
import { patientTabData, selectedCardHeading } from '../Dashboard/dataFormatters';
import dayjs from 'dayjs';
import PatientDashboardGraphs from './PatientDashboardGraphs';
import PatientOverviewEasyFilter from '../Dashboard/PatientOverviewEasyFilter';
import { getProviderName, handleLeftAction, handleRightAction } from '../Dashboard/dashboardFunctions';

const PatientDashboard = () => {
    // states
    const [selectedFilter, setSelectedFilter] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [startDate, setStartDate]= useState(dayjs(new Date()))
    const [endDate, setEndDate] = useState(dayjs(new Date()))
    const [isLoading, setIsLoading] = useState(true)
    const [filter, setFilter] = useState(false)
    const [patientsOverviewData, setPatientsOverviewData] = useState({patientsOverviewGraphData})
    const [patientsCardData, setPatientsCardData] = useState([])
    const [selectedCard, setSelectedCard] = useState(null)
    const [lastSelectedFilter, setLastSelectedFilter] = useState('Today')
    const [dateRecord, setDateRecord] = useState({startDate,endDate,lastSelectedFilter:'Today'})
    const [providerOrganizationName, setProviderOrganizationName] = useState('')

    // functions
    const getOverviewData = async() => {
        try{ setIsLoading(true)
         const data = await getPatientOverviewChartDetails(selectedFilter)
         if(data?.newPatientsAdded){
             const labels = dataLabels(data)
             const datasets = dataSets(data, labels)
             setPatientsOverviewData({ labels, datasets })
             setShowModal(false)
             setPatientsCardData(data)
 
         }
        }
        catch(e){
             console.log(e,'error fetching data')
             ToastBar.error('error fetching data')
        }
         finally{
             setIsLoading(false)
         }
     }

     const dateFilterAction = (date) => {
        setSelectedFilter(`${formatDateYMD(new Date(date ?? startDate))}/${formatDateYMD(new Date(endDate))}`)
        setFilter(!filter)
    }
    
    //  use Effects
     useEffect(()=>{
        getOverviewData()
     },[selectedFilter, filter])

     useEffect(()=>{
        getProviderName(setProviderOrganizationName)
    },[])

    return (
        <>
            <HeadingPlusFilter 
            onClick={()=>setShowModal(true)} 
            heading={providerOrganizationName || 'Patients Overview'} 
            reset={selectedCard ? ()=>setSelectedCard(null) : null} 
            children={ <PatientOverviewEasyFilter
                date={
                    formatDateMDY(new Date(startDate)) === formatDateMDY(new Date(endDate)) 
                    ? 
                    `${formatDateToReadable(formatDateMDY(new Date(startDate)))}`
                    :
                    `${formatDateToReadable(formatDateMDY(new Date(startDate)))} -To- ${formatDateToReadable(formatDateMDY(new Date(endDate)))}`}
                    leftAction={() =>handleLeftAction(lastSelectedFilter,startDate,setStartDate,dateFilterAction,setDateRecord,dateRecord)}
                    rightAction={()=>handleRightAction(lastSelectedFilter,startDate,setStartDate,dateFilterAction,setDateRecord,dateRecord)}
                    disableRightAction={formatDateMDY(new Date(startDate)) === formatDateMDY(new Date(endDate)) }
                    onClick={()=>setShowModal(true)}
                />}
            />
            <PatientDashboardCards data={patientTabData(patientsCardData)} isLoading={isLoading} setSelectedCard={setSelectedCard}/>
            {selectedCard &&
    <Typography sx={{...sharedStyles?.bm1em,...sharedStyles?.horizontalCenteredFlex}}>
        {selectedCardHeading(selectedCard)}
    </Typography>
    }
            <HorizontalBox sx={sharedStyles?.justifyCenter}>
                <Box sx={styles?.chartParentContainer}>
                    <Box sx={styles?.chartContainer}>
                        <PatientDashboardGraphs isLoading={isLoading} 
                            patientsOverviewData={patientsOverviewData} selectedCard={selectedCard}/>
                    </Box>
                </Box>
            </HorizontalBox>
            <DateFilterModal
                open={showModal}
                handleClose={() => setShowModal(false)}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                action={dateFilterAction}
                isLoading={isLoading}
                setLastSelectedFilter={setLastSelectedFilter}
                dateRecord={dateRecord}
                lastSelectedFilter={lastSelectedFilter}
            />
         
        </>
    )
}

export default PatientDashboard


