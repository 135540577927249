import React, { useEffect, useState } from 'react'
import styles from "../../css/syncpatient.module.css";
import { styles as javaScriptStyles } from './styles';
import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { isAlphabetOnly } from '../../utils/regexTestFunctions';
import NpiResultsTable from '../../components/table/NpiResultsTable';
import { npiDefault, unitedStates } from '../../utils/staticData';
import { npiSync } from '../../services/NpiFinderInquiry/npiFinder';
import PageLoader from '../../components/loader/PageLoader';
import { postalCodeFormat } from '../../utils/valueFormatter/Formats';
import { useLocation } from 'react-router-dom';
import { getLocalData, removeLocalData, setLocalData } from '../../utils/localStorageFunctions';
import { sharedStyles } from '../../sharedStyles';
import SearchWithoutDropdown from '../../components/searchWithoutDropdown/SearchWithoutDropdown';



const FindNpiDetails = () => {
    const [npiInfo, setNpiInfo] = useState(npiDefault);
    const [data, setData] = useState([]);
    const [input, setInput] = useState("");
    const [filterNpi, setFilterNpi] = useState("");
    const [isLoading, setIsloading] = useState(false);
    const [results, showResults] = useState(false);
    const [showMultiple, setShowMultiple] = useState(false);
    const { formWrapper } = styles;
    const location = useLocation();
    const { state } = location;

    // const handleChange = (e) => {
    //     if (e.target && e.target.name) {
    //         const { name, value } = e.target;
    //         if (name === "providerFirstName" || name === "providerLastName") {
    //             if (!isAlphabetOnly(value)) {
    //                 return;
    //             }
    //         } else if (name === "postalCode") {
    //             let formattedValue = postalCodeFormat(value);
    //             setNpiInfo((prevDetails) => ({
    //                 ...prevDetails,
    //                 [name]: formattedValue,
    //             }));
    //             return;
    //         }
    //         setNpiInfo((prevState) => ({
    //             ...prevState,
    //             [name]: value,
    //         }));
    //     }
    // };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const { npi, providerFirstName, providerLastName, postalCode, state, city } = npiInfo;
    //     let payload = {}
    //     if (!npi || !providerFirstName || !providerLastName || !postalCode || !state || !city) {
    //         payload = {};
    //         setShowMultiple(true)
    //     } else {
    //         payload = {
    //             npi: +npi,
    //             providerFirstName,
    //             providerLastName,
    //             postalCode,
    //             state,
    //             city
    //         }
    //     }
    //     setLocalData("payload", payload)
    //     callApi(payload)
    // }

    const callApi = async (payload) => {
        setIsloading(true);
        try {
            const response = await npiSync(payload);
            if (response?.data?.data?.length > 0) {
                setData(response?.data?.data);
                showResults(true);
                setNpiInfo({
                    city: "",
                    providerFirstName: "",
                    providerLastName: "",
                    npi: null,
                    postalCode: "",
                    state: ""
                })
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsloading(false);
        }
    }

    // const clear = () => {
    //     showResults(false)
    //     setShowMultiple(false)
    //     setNpiInfo({
    //         city: "",
    //         providerFirstName: "",
    //         providerLastName: "",
    //         npi: null,
    //         postalCode: "",
    //         state: ""
    //     })
    //     removeLocalData("payload")
    // }

    // const handleSearch = (event, value) => {
    //     setInput(value);
    //     setFilterNpi(value);
    // }

    useEffect(() => {
       callApi(npiInfo)
    }, [])

    return (
        <div>
            {
                isLoading ?
                    (
                        <PageLoader />
                    )
                    :
                    <>
                        <Box sx={sharedStyles?.bm1em}>
                            <NpiResultsTable data={data} filterNpi={filterNpi} />
                        </Box>
                    </>


            }
        </div>
    )
}

export default FindNpiDetails;
