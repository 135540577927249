import { Box } from '@mui/material'
import React from 'react'

const PayerDetailsCard = ({ children, sx }) => {
    return (
        <Box sx={{ boxShadow: '0px 4px 8px #5E8A75', borderRadius: 2, bgcolor: 'white', height: 130, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, cursor: 'pointer', ...sx }}>
            {children}
        </Box>
    )
}

export default PayerDetailsCard