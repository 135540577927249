import { Button, CircularProgress } from "@mui/material"
import React from "react";

const ActionButton = (props) => {

    return (
        <Button className={props?.className} onClick={props?.onChange} variant={props?.variant} type={props?.type} disabled={props?.disabled}>
            {(props?.showLoading ? <CircularProgress /> : props?.label)}
        </Button>);

};
export default ActionButton;