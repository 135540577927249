import React from 'react'
import PayersDetail from '../../components/table/PayersDetail';

const PayerSearch = () => {

    return (
        <div>
                <PayersDetail />
        </div>
    )
}

export default PayerSearch
