import React, { useEffect, useState } from 'react'
import ResponsiveHorizontalBox from '../../components/shared/ResponsiveHorizontalBox'
import { getDashboardCounts, translateCardType } from './DashboardFunctions'
import DashboardCard from '../../components/DashboardCard/DashboardCard'
import PageLoader from '../../components/loader/PageLoader'

const DiscoveryDashboardCards = () => {
    const [cardsData, setCardsData] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(()=>{
        getDashboardCounts(setCardsData,setLoading)
    },[])

    return (
        loading ? <PageLoader height={'20vh'}/> 
            :
            <ResponsiveHorizontalBox>
            {cardsData?.length ? cardsData?.map(({type,count,repeated}, index) => {
                return (
                    <DashboardCard heading={translateCardType(type,repeated)} count={count} index={index} />
                )
            }) : ''}
        </ResponsiveHorizontalBox>
    )
}
export default DiscoveryDashboardCards