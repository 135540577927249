import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { MdOutlineNewLabel } from 'react-icons/md'
import { colors } from '../../utils/colors'
import { styles } from './styles'
import DashboardGridItemContainer from './ClaimsComponents/DashboardGridItemContainer'
import { getApprovedClaims, getMostCommonDenialReason, getPendingClaims } from './ClaimFunctions'
import DashboardGridCard from './ClaimsComponents/DashboardGridCard'

const ClaimDashboardCards = () => {
    const [approvedClaimsData, setApprovedClaimsData] = useState()
    const [pendingClaimsData, setPendingClaimsData] = useState()
    const [mostCommonDenialReason, setMostCommonDenialReason] = useState('')
   const [approvedClaimsLoader, setApprovedClaimsLoader] = useState(false)
   const [pendingClaimsLoader, setPendingClaimsLoader] = useState(false)
   const [denialReasonLoader, setDenialReasonLoader] = useState(false)

    useEffect(()=>{
        getApprovedClaims(setApprovedClaimsData,setApprovedClaimsLoader)
        getPendingClaims(setPendingClaimsData, setPendingClaimsLoader)
        getMostCommonDenialReason(setMostCommonDenialReason,setDenialReasonLoader)
    },[])

    return (
        <Grid container spacing={2} alignItems="stretch"> 
            <DashboardGridItemContainer icon={<MdOutlineNewLabel fill={colors?.themeGreen} />}>
                    <DashboardGridCard
                    heading={'Number of Claims:'}
                    approvedClaimsData={approvedClaimsData?.length}
                    pendingClaimsData={pendingClaimsData?.length}
                    loader={{
                        approvedClaimsLoader,
                        pendingClaimsLoader
                    }}
                    />
            </DashboardGridItemContainer>
            <DashboardGridItemContainer icon={<MdOutlineNewLabel fill={colors?.themeGreen} />}>
            <DashboardGridCard
                    heading={'Value of Claims:'}
                    approvedClaimsData={approvedClaimsData?.totalPaidAmount}
                    pendingClaimsData={pendingClaimsData?.totalPaidAmount}
                    loader={{
                        approvedClaimsLoader,
                        pendingClaimsLoader
                    }}
                    />
            </DashboardGridItemContainer>
            <DashboardGridItemContainer icon={<MdOutlineNewLabel fill={colors?.themeGreen} />}>
            <Box>
                        <Typography sx={styles.cardText} fontWeight="bold">Average processing time for claims:</Typography>
                        <Typography color={colors?.themeGreen}>--</Typography>
                        <Typography 
                        sx={styles.cardText}
                        fontWeight="bold">Most common reasons for claim denial:</Typography>
                        {
                            denialReasonLoader ? <CircularProgress color='success' size={17}/> :
                            <Typography color={colors?.themeGreen}>{mostCommonDenialReason || '--'}</Typography>}
                    </Box>
            </DashboardGridItemContainer>

        </Grid>
    )
}

export default ClaimDashboardCards
