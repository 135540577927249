
import React, { useState, useEffect } from 'react';
import DetailTab from '../../components/Plandetailtabs/Detailtab';
import CoverageTab from '../../components/Plandetailtabs/coveragetab';
import DemographicInfoTab from '../../components/Plandetailtabs/Demographic';
import Innetwork from '../../components/Plandetailtabs/Innetwork';
import Outofnetwork from '../../components/Plandetailtabs/Outofnetwork';
import Speciality from '../../components/Plandetailtabs/Speciality';
import { Box, CircularProgress } from '@mui/material';
import { sharedStyles } from '../../sharedStyles';

const Plandetail = ({ tabname, rowData,data, detailData, detailsLoading:isLoading}) => {
  const [tabdata, setTabdata] = useState([]);
  const [patientInsuranceTabData, setPatientInsuranceTabData] = useState();


  useEffect(() => {
          let planSummaryData = [];
          if (tabname === 'detail') {
            planSummaryData = data?.insuranceDiscoveryDto?.planCoverageSummary;
          } else if (tabname === 'coverage') {
            planSummaryData = data?.insuranceDiscoveryDto?.pcpAuthInfoSummary;
          } else if (tabname === 'Innetwork' || tabname === 'Outofnetwork' || tabname === 'Speciality' || tabname === 'DemographicInfo') {
            planSummaryData = data?.insuranceDiscoveryDto;
          }
          setTabdata([planSummaryData]);
          setPatientInsuranceTabData(detailData)
  }, [data, tabname]);


  const tabComponents = {
    detail: DetailTab,
    coverage: CoverageTab,
    DemographicInfo: DemographicInfoTab,
    Innetwork: Innetwork,
    Outofnetwork: Outofnetwork,
    Speciality: Speciality
  };
  const TabComponent = tabComponents[tabname];
  return (
    <>
      {
        isLoading ?
          <Box sx={sharedStyles?.horizontalCenteredFlex}>
            <CircularProgress color="success" size={22} />
          </Box> :
          <div>
            {tabdata?.length ? tabdata?.map((item, index) => (
              <div key={index}>
                {TabComponent ? <TabComponent data={item} patientInsuranceTabData={patientInsuranceTabData} tabname={tabname} patientId={rowData?.ID} /> : null}
              </div>
            ))
              : ''
            }
          </div>}
    </>
  );
};

export default Plandetail;