import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import styles from '../../css/DentalSeperatedDetails.module.css';

const DentalSeperatedDetails = ({ array, boxSx, noMt }) => {
    return (
        <Box sx={{ ...boxSx }} className={`${noMt ? styles.noMarginTop : styles.box}`}>
            {array?.map(({ name, value }, index) => (
                <Box key={index} className={styles.marginBottom}>
                    {name === "Limitations" ? (
                        <>
                            <Typography variant="h6" component="span" className={styles.limitationsTitle}>
                                {name}:
                            </Typography>
                            <Grid container spacing={1} className={styles.limitationsGrid}>
                                {value?.monetoryamount && (
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            <span className={styles.limitationsText}>Monetory Amount: </span>
                                            {value?.monetoryamount || '--'}
                                        </Typography>
                                    </Grid>
                                )}
                                {value?.planCoverageDescription && (
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            <span className={styles.limitationsText}>Plan Coverage Description: </span>
                                            {value?.planCoverageDescription || '--'}
                                        </Typography>
                                    </Grid>
                                )}
                                {value?.monetoryamount1 && (
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            <span className={styles.limitationsText}>Monetory Amount 1: </span>
                                            {value?.monetoryamount1 || '--'}
                                        </Typography>
                                    </Grid>
                                )}
                                {value?.planCoverageDescription1 && (
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            <span className={styles.limitationsText}>Plan Coverage Description 1: </span>
                                            {value?.planCoverageDescription1 || '--'}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <Box className={styles.textContainer}>
                            <Typography variant="body1">
                                <span className={styles.textBold}>{name}:</span> {value || '--'}
                            </Typography>
                        </Box>
                    )}
                </Box>
            ))}
        </Box>
    );
};

export default DentalSeperatedDetails;
