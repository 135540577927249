import React from 'react'
import { Box } from '@mui/material';
import DiscoveryDashboardCards from './DiscoveryDashboardCards';
import { sharedStyles } from '../../sharedStyles';
import DiscoveryAndInsuranceProvider from './DiscoveryAndInsuranceProvider';
import TrendsChart from './TrendsChart';
import InsuranceTrendsChart from './InsuranceTrendsChart';

const DiscoveryDashboard = () => {
    
    return (
        <>
            <DiscoveryDashboardCards />
            <Box sx={sharedStyles?.mt5px}>
            <DiscoveryAndInsuranceProvider/>
            </Box>
            <TrendsChart/>
            {/* <Box sx={sharedStyles?.mt5px}>
            <InsuranceTrendsChart/>
            </Box> */}
        </>
    )
}
export default DiscoveryDashboard


