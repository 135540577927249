import { colors } from "../../utils/colors";

export const styles = {
    graphContainer: {
        justifyContent: 'space-between', mb: 2
    },
    cardValue: {
        fontWeight: 'bold'
    },
    cardText: { marginY: 2 },
    claimTablesContainer:{
        display:'flex',
        flexDirection:'column',
        gap:5
    },
    scheduledActionsContainer:{
        gap:2,
        justifyContent:'flex-end',
        marginTop:0.5,
    },
    viewDetails:{ 
        backgroundColor: colors?.green, 
        border: 'none', 
        color: colors?.white, 
        fontSize: 'small'
    },
    statusStyle:{
        backgroundColor: colors?.white,
        color: colors?.green,
    },
    dashboardItemContainer:{
        paddingX: { xs: 1, sm: 2 },
        display: 'flex',
        flexDirection: 'column', 
        boxShadow: 3,
        borderRadius: 4,
        height: '100%',
        width:'100%' ,
        paddingY: 2,
    }
}