import React from 'react'
import ClaimDashboardCards from './ClaimDashboardCards';
import PayerDetails from '../Dashboard/PayerDetails'

const ClaimDashboard = () => {
    return (
        <>
            <ClaimDashboardCards />
            {/* importing from main dashboard folder, using same thing there */}
            <PayerDetails noMargin/>
        </>
    )
}

export default ClaimDashboard


