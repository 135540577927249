import { Box, Typography } from '@mui/material'
import React from 'react'
import Tabs from '../../components/Tabs'


const FindInsurance = () => {
    return (
        <section className=''>
            <Box className='heading'>
                <h1>Insurance</h1>
            </Box>
            <Box
                className='insurance-box'
                sx={{
                    boxShadow: '-3px -2px 24px 13px #eee',
                    margin: '4em auto',
                    height: '80%'
                }}>
                <div>
                    <Box sx={{ paddingLeft: '1em' , paddingTop: '1em', paddingBottom: '1em' }} className='form-header'>
                        <Box className='form-header-'>
                            <Typography fontSize={32} sx={{fontWeight:300}}>Find Insurance</Typography>
                        </Box>
                    </Box>

                        <Tabs />
                </div>
            </Box>
        </section>
    )
}

export default FindInsurance