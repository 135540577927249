import React, { useState } from 'react';
import HorizontalHeadingPlusText from '../shared/HorizontalHeadingPlusText';
import { update_UpdatePatientInsuranceDetails } from '../../services/registration';
import { Button, Grid, Paper, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import styles from "../../css/discoveryDetails.module.css";

const EligibilityDemographicInfoTab = ({ data, patientInsuranceTabData, tabname, patientId }) => {
  const { leftBox, header, rightBox, button, buttonGrid } = styles; 
  const demographicData = [
    { name: 'Policy Type', value: data?.planCoverageSummary?.policyType || 'N/A' },
    { name: 'Authorization/Certification Required', value: data?.serviceDetails?.authorizationOrCertificationRequired || 'N/A' },
    { name: 'Plan Network ID Number', value: data?.planCoverageSummary?.planNetworkID || 'N/A' },
    { name: 'Full Name', value: data?.demographicInfo?.subscriber?.fullName || 'N/A' },
    { name: 'Address', value: data?.demographicInfo?.subscriber?.address1 || 'N/A' },
    { name: 'City', value: data?.demographicInfo?.subscriber?.city || 'N/A' },
    { name: 'State', value: data?.demographicInfo?.subscriber?.state || 'N/A' },
    { name: 'Zip', value: data?.demographicInfo?.subscriber?.zip || 'N/A' },
    { name: 'Uniform Resource Locator (URL)', value: data?.detailsURL || 'N/A' },
    { name: 'Payor ID', value: data?.payerId || 'N/A' },
  ];

  const [patientInsuranceDetailTabData, setPatientInsuranceDetailTabData] = useState(patientInsuranceTabData); 

  const handleSavePatientsDetails = async (tabName, patientId) => {
    try {
      const patientUpdatedData = await update_UpdatePatientInsuranceDetails(tabName, patientId);
      if (patientUpdatedData?.patientData) {
        setPatientInsuranceDetailTabData(patientUpdatedData?.patientData);
      } else {
        toast('Demographic detail is empty');
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={leftBox}>
            <Typography variant="h5" align="center" className={header}>
              Payer
            </Typography>
            <Typography align="center" variant="h5" className={header}>
              {tabname === "detail"
                ? "Plan Details"
                : tabname === "coverage"
                ? "Coverage Details"
                : tabname === "DemographicInfo"
                ? "DemographicInfo Details"
                : tabname === "Innetwork"
                ? "In Network"
                : tabname === "Outofnetwork"
                ? "Out of Network"
                : tabname === "Speciality"
                ? "Speciality"
                : ""}
            </Typography>
            <hr/>
            {demographicData?.length && demographicData?.map((item, index) => (
            <HorizontalHeadingPlusText heading={item?.name} text={item?.value} key={index} />
            ))}
          </Paper>
        </Grid>

        {/* Button */}
        <Grid item xs={12} md={2} className={buttonGrid}>
          <Button variant="outlined" className={button} onClick={()=>handleSavePatientsDetails(tabname, patientId)} >Sync with</Button>
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={rightBox}>
            <Typography variant="h5" align="center" className={header}>
              Practice
            </Typography>
            <Typography align="center" variant="h5" className={header}>
              {tabname === "detail"
                ? "Plan Details"
                : tabname === "coverage"
                ? "Coverage Details"
                : tabname === "DemographicInfo"
                ? "DemographicInfo Details"
                : tabname === "Innetwork"
                ? "In Network"
                : tabname === "Outofnetwork"
                ? "Out of Network"
                : tabname === "Speciality"
                ? "Speciality"
                : ""}
            </Typography>
            <hr />
            <HorizontalHeadingPlusText heading={'Policy Type'} text={patientInsuranceDetailTabData?.demographicInfo?.policyType || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Authorization/Certification Required'} text={patientInsuranceDetailTabData?.demographicInfo
                      ?.authorizationOrCertificationRequired || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Plan Network ID Number'} text={patientInsuranceDetailTabData?.demographicInfo?.planNetworkID || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Full Name'} text={patientInsuranceDetailTabData?.demographicInfo?.fullName || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Address'} text={patientInsuranceDetailTabData?.demographicInfo?.address1 || 'N/A'} />
            <HorizontalHeadingPlusText heading={'City'} text={patientInsuranceDetailTabData?.demographicInfo?.city || 'N/A'} />
            <HorizontalHeadingPlusText heading={'State'} text={patientInsuranceDetailTabData?.demographicInfo?.state || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Zip'} text={patientInsuranceDetailTabData?.demographicInfo?.zip || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Uniform Resource Locator (URL)'} text={patientInsuranceDetailTabData?.demographicInfo?.detailsURL || 'N/A'} />
            <HorizontalHeadingPlusText heading={'Payor ID'} text={patientInsuranceDetailTabData?.demographicInfo?.payerId || 'N/A'} />      
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default EligibilityDemographicInfoTab;
