import axios from 'axios';
import { toast } from 'react-hot-toast';
import { getLocalData } from "../utils/localStorageFunctions";
const API_BASE_URL = process.env.REACT_APP_API_URL;
const doctorId = getLocalData('selectedNpi')?.npiNumber ?? '';

export const fetchSchedule = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/Doctor/GetDoctorById/${doctorId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching schedule:', error);
        toast.error('Failed to fetch schedule');
    }
};

export const saveSchedule = async (schedule) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/Doctor/AddDoctor`, schedule);
        return response.data;
    } catch (error) {
        console.error('Error saving schedule:', error);
        toast.error('Failed to save schedule');
    }
};

export const fetchDoctorInfo = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/Doctor/GetDoctorById/${doctorId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching doctor information:', error);
        toast.error('Failed to fetch doctor information');
    }
};