import React from 'react'
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import GridComponent from '../../css/GridComponent';
import { capitalizedFirstLetter } from '../../utils/reusableFunctions';

const EligibilitySearchresultdata = () => {

    const pagename = "EligibilitySearchresultdata";
    const location = useLocation();
    const EligibilitysearchData = location.state?.searchData || [];
    const params = new URLSearchParams(location?.search);
    const throughEligibility = params.get('through') === 'Eligibility'
    const rowData = !throughEligibility ? location.state?.rowData || {} : location?.state?.searchData?.eligibilityDto 

    return (
        <Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1.2em',
          }}>
          {!throughEligibility ? <Box sx={{ width: '25%' }}>
            {rowData?.Patient ? (
              <p>{rowData?.Patient}</p>
            ) : (
              <p>{`${EligibilitysearchData?.insuranceDiscoveryDto?.patientData?.firstName} ${EligibilitysearchData?.insuranceDiscoveryDto?.patientData?.lastName}`}</p>
            )}

          </Box>
        :
        <Box sx={{ width: '25%' }}>
        {rowData ? (
          <p>{capitalizedFirstLetter(rowData?.patientData?.firstName + ' ' + rowData?.patientData?.lastName)}</p>
        ) : (
          <p>{`${EligibilitysearchData?.insuranceDiscoveryDto?.patientData?.firstName} ${EligibilitysearchData?.insuranceDiscoveryDto?.patientData?.lastName}`}</p>
        )}

      </Box>
        }
            
          </Box>
          <Box sx={{ marginTop: '2em' }}>
        
          </Box>
          <GridComponent
         pagename={pagename}
         searchData={EligibilitysearchData}
         eligibilitycheckData={rowData}
         throughEligibility={throughEligibility}
          />
        </Box>
      )
}

export default EligibilitySearchresultdata