import React from 'react'
import HorizontalBox from '../shared/HorizontalBox'
import HorizontalHeadingPlusText from '../shared/HorizontalHeadingPlusText'
import { Typography } from '@mui/material'

const SeperatedDetails = ({ array, boxSx, noMt }) => {
    return (
        <HorizontalBox sx={{ mt: noMt ? 0 : 2, flexWrap: 'wrap', gap: 2, borderBottom: '1px solid lightGray', ...boxSx }}>
            {array?.map(({ name, value }, index) => {
                return (
                    <HorizontalBox key={index} >
                        <HorizontalHeadingPlusText heading={name} text={value || '--'} key={index} />
                        {index < array?.length - 1 && <Typography sx={{ fontWeight: 'bold', ml: 1 }}>|</Typography>}
                    </HorizontalBox>


                )
            })}
        </HorizontalBox>

    )
}

export default SeperatedDetails