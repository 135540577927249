import React, { useEffect, useState } from 'react';
import HorizontalHeadingPlusText from '../shared/HorizontalHeadingPlusText';
import { update_UpdatePatientInsuranceDetails } from '../../services/registration';
import { Button, Grid, Paper, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import styles from "../../css/discoveryDetails.module.css";
import { formatDemographicInfo, getTabNameLabel } from '../../Pages/Discovery/DiscoveryFunctions';
import SyncButton from './SyncButton';

const DemographicInfoTab = ({ data, patientInsuranceTabData, tabname, patientId }) => {
  const { leftBox, header, rightBox, button, buttonGrid } = styles; 
  const demographicData = [
    { name: 'Policy Type', value: data?.planCoverageSummary?.policyType || 'N/A' },
    { name: 'Authorization/Certification Required', value: data?.serviceDetails?.authorizationOrCertificationRequired || 'N/A' },
    { name: 'Plan Network ID Number', value: data?.planCoverageSummary?.planNetworkID || 'N/A' },
    { name: 'Full Name', value: data?.demographicInfo?.subscriber?.fullName || 'N/A' },
    { name: 'Address', value: data?.demographicInfo?.subscriber?.address1 || 'N/A' },
    { name: 'City', value: data?.demographicInfo?.subscriber?.city || 'N/A' },
    { name: 'State', value: data?.demographicInfo?.subscriber?.state || 'N/A' },
    { name: 'Zip', value: data?.demographicInfo?.subscriber?.zip || 'N/A' },
    { name: 'Uniform Resource Locator (URL)', value: data?.detailsURL || 'N/A' },
    { name: 'Payor ID', value: data?.payerId || 'N/A' },
  ];

  const [patientInsuranceDetailTabData, setPatientInsuranceDetailTabData] = useState([]); 
  const [isLoading, setIsLoading] = useState(false)

  useEffect(()=>{
    setPatientInsuranceDetailTabData(formatDemographicInfo(patientInsuranceTabData))
  },[patientInsuranceTabData])

  const handleSavePatientsDetails = async (tabName, patientId) => {
    try {
      setIsLoading(true)
      const patientUpdatedData = await update_UpdatePatientInsuranceDetails(tabName, patientId);
      if (patientUpdatedData?.patientData) {
        setPatientInsuranceDetailTabData(formatDemographicInfo(patientUpdatedData?.patientData?.demographicInfo));
      } else {
        toast('Demographic detail is empty');
      }
    } catch (error) {
      console.error(error);
    }
    finally{
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={leftBox}>
            <Typography variant="h5" align="center" className={header}>
              Payer
            </Typography>
            <Typography align="center" variant="h5" className={header}>
            {getTabNameLabel(tabname)}
            </Typography>
            <hr/>
            {demographicData?.length && demographicData?.map((item, index) => (
            <HorizontalHeadingPlusText heading={item?.name} text={item?.value} key={index} />
            ))}
          </Paper>
        </Grid>

        {/* Button */}
        <Grid item xs={12} md={2} className={buttonGrid}>
          <SyncButton onClick={()=>handleSavePatientsDetails(tabname, patientId)} isLoading={isLoading}/> 
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={rightBox}>
            <Typography variant="h5" align="center" className={header}>
              Practice
            </Typography>
            <Typography align="center" variant="h5" className={header}>
            {getTabNameLabel(tabname)}
            </Typography>
            <hr />
            {
             patientInsuranceDetailTabData?.length && patientInsuranceDetailTabData?.map(({heading,text},index)=>{
                return(
                  <HorizontalHeadingPlusText heading={heading} text={text|| 'N/A'} key={index} /> 
                )
              })
            }  
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default DemographicInfoTab;
