import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid, Tabs } from '@mui/material';
import styles from '../../css/addpatient.module.css'
import { useState, useEffect } from 'react';
import MyTabs from '../../components/tabs/MyTabs'
import { useLocation, useNavigate, } from 'react-router-dom';
import EligibilityPlandetail from './EligibilityPlandetail';
import { get_GeteligibilitydetailsByrequestId } from '../../services/registration';
import { CgCalendarDates } from "react-icons/cg";
import { IoIosJournal } from "react-icons/io";
import { PiShareNetworkDuotone } from "react-icons/pi";
import { FaCcAmazonPay } from "react-icons/fa";
import { FaHandsHelping } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";
import { colors } from '../../utils/colors';
import { FaIdCard } from "react-icons/fa";
import DetailsHeader from '../../components/shared/DetailsHeader';
import { SiInstatus } from 'react-icons/si';
import { sharedStyles } from '../../sharedStyles';
import { failedGetApi } from '../../utils/reusableFunctions';


function Eligibilitydetails1() {
  const { formWrapper } = styles;
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const [searchdata, setSearchdata] = useState([]);

  // route handlers
  const rowDataString = new URLSearchParams(location.search).get('rowData');
  const rowData = rowDataString ? JSON.parse(decodeURIComponent(rowDataString)) : null;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (rowData && rowData?.ID) {
          let requestID = rowData?.RequestId;
          let PatientId = rowData?.ID;
          const transactionDate = formatTransactionDate(rowData?.TransactionDate);
          const data = await get_GeteligibilitydetailsByrequestId(requestID,PatientId,transactionDate);
         if(data){
          const deductibleData = data?.eligibilityDto?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingInNet?.value || data?.eligibilityDto?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingInNet;
          const memberId = data?.eligibilityDto?.miscellaneousInfoSummary?.memberID
          const requestId = data?.eligibilityDto?.requestID ;
          const effectiveDate = data?.eligibilityDto?.planCoverageSummary?.effectiveDate
          const policyType = data?.eligibilityDto?.patientData?.planDetails?.policyType
          const isProviderInNetwork = data?.eligibilityDto?.isProviderInNetwork
           setSearchdata({
             deductibleData,
             requestId,
             memberId,
             effectiveDate,
             policyType,
             isProviderInNetwork
           });
         }
         else{
          failedGetApi()
         }
        }

      } catch (error) {
       failedGetApi(error)
      }
    };

    fetchData()
  }, []);
  const formatTransactionDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  // states using route handlers
  const [topRightButtons] = useState([
  
    { name: 'New Search', action: () => navigate('/EligibilityTab?preSelectedTab=1') },
    { name: 'Back to Results', action: () => navigate(-1) }
  ])

 


  const handleTabChange = (newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const tabs =
    [

      { label: "Plan Detail", value: <EligibilityPlandetail tabname="detail" rowData={rowData} /> },
      { label: "Coverage Details", value: <EligibilityPlandetail tabname="coverage" rowData={rowData} /> },
      { label: "DemographicInfo", value: <EligibilityPlandetail tabname="DemographicInfo" rowData={rowData} /> },
      { label: "In-Network", value: <EligibilityPlandetail tabname="Innetwork" rowData={rowData} />, changeTab: 3 },
      {
        label: "Out-of-Network", value: <EligibilityPlandetail tabname="Outofnetwork" rowData={rowData} />, changeTab: 4,
      },
      {
        label: "Speciality", value: <EligibilityPlandetail tabname="Speciality" rowData={rowData} />, changeTab: 5,
      },

    ]

  const topCardsItems = [
    { name: 'Status', value: rowData?.Status || 'N/A', icon: <SiInstatus fill={colors?.themeGreen} /> },
    { name: 'Network', value: searchdata?.isProviderInNetwork || 'N/A', icon: <PiShareNetworkDuotone fill={colors?.themeGreen} /> },
    { name: 'Payer', value: rowData?.Insurance || 'N/A', icon: <FaCcAmazonPay fill={colors?.themeGreen} /> },
    { name: 'Member Id', value: searchdata?.memberId || 'N/A', icon: <FaIdCard fill={colors?.themeGreen} /> },
    { name: 'Copay', value: rowData?.Copay || 'N/A', icon: <FaHandsHelping fill={colors?.themeGreen} /> },
    { name: 'Deductible', value: searchdata?.deductibleData || 'N/A', icon: <FaMinusCircle fill={colors?.themeGreen} /> },
    { name: 'Plan Begin Date', value: searchdata?.effectiveDate || 'N/A', icon: <CgCalendarDates fill={colors?.themeGreen} /> },
    { name: 'Plan Type', value: searchdata?.policyType || 'N/A', icon: <IoIosJournal fill={colors?.themeGreen} /> },
  ]

  return (
    <Box sx={{ width: { xs: '100%' } }}>
      <DetailsHeader topRightButtons={topRightButtons} name={rowData?.Patient} dob={rowData?.DOB} />
      <Box >
        <Box>

          <Box className={formWrapper}>
            <Grid container rowSpacing={3} columnSpacing={8} sx={sharedStyles?.mt5px}>

              {topCardsItems?.map((elem, index) => {
                return (
                  <Grid item xs={12} sm={6} lg={3} key={index} >
                    <Box sx={{ boxShadow: '0px 4px 8px #5E8A75', borderRadius: 2, bgcolor: 'white', height: 130, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, cursor: 'pointer', }}>
                      {elem?.icon}
                      <Box sx={{ textAlign: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold', color: colors?.themeGreen }}>{elem?.name}</Typography>
                        <Typography>{elem?.value}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>

            <Box sx={{ width: '100%', mt: 5 }}>


              <Tabs>
                <MyTabs tabs={tabs} onChange={handleTabChange} activeTab={tabIndex}
                />
              </Tabs>

            </Box>
          </Box>
        </Box>

      </Box>
    </Box>



  );
}
export default Eligibilitydetails1;