import { API_BASE_URL } from "../utils/staticData";
import axios from "axios";

export const get_Vendors = async () => {
    const url = `${API_BASE_URL}/api/Vendor/GetAllVendors`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);

    }
};