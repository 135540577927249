import axios from 'axios';
import toast from 'react-hot-toast';
import { getLocalData } from '../utils/localStorageFunctions';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const handleResponse = (response) => response?.data;

const handleError = (error, errorMessage) => {
    console.error(errorMessage, error);
    toast.error(errorMessage);
    return null;
};

export const getAllAppointments = async () => {
    const url = new URL('/api/Appointment/GetAllAppointments', API_BASE_URL);
    const params = new URLSearchParams();
    params?.append('npiId', getLocalData('selectedNpi')?.id ?? '')


    url.search = params.toString();

    try {
        const response = await axios.get(url);
        return handleResponse(response);
    } catch (error) {
        return handleError(error, 'Failed to fetch appointments');
    }
};

export const getAppointmentById = async (appointmentId) => {
    const url = `${API_BASE_URL}/api/Appointment/GetAppointmentById/${appointmentId}`;
    try {
        const response = await axios.get(url);
        return handleResponse(response);
    } catch (error) {
        return handleError(error, 'Failed to fetch appointment by ID');
    }
};

export const addAppointment = async (appointment) => {
  const url = `${API_BASE_URL}/api/Appointment/AddAppointment`;
  try {
    const response = await axios.post(url, appointment, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error, 'Failed to add appointment');
  }
};

export const updateAppointment = async (appointmentId, appointment) => {
    const url = `${API_BASE_URL}/api/Appointment/UpdateAppointment/${appointmentId}`;
    try {
        const response = await axios.put(url, appointment, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        return handleError(error, 'Failed to update appointment');
    }
};

export const deleteAppointment = async (appointmentId) => {
    const url = `${API_BASE_URL}/api/Appointment/DeleteAppointment/${appointmentId}`;
    try {
        const response = await axios.delete(url);
        return handleResponse(response);
    } catch (error) {
        return handleError(error, 'Failed to delete appointment');
    }
};