import { Box } from '@mui/material'
import React from 'react'

const HorizontalBox = ({ children, sx,className,onClick,alignItems,gap }) => {
    return (
        <Box sx={{ display: 'flex', alignItems:alignItems ?? 'center',gap:gap ,...sx }}
        className={className}
        onClick={onClick}
        >
            {children}
        </Box>
    )
}

export default HorizontalBox