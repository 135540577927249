export const getLocalData = (key) => {
    return JSON.parse(localStorage?.getItem(key))
}

export const setLocalData = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const clearAllLocalData = () => {
    localStorage.clear();
}

export const removeLocalData = (key) => {
    localStorage.removeItem(key);
}