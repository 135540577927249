import React, { useState, useEffect } from 'react'
import SelectTwo from '../../components/select/SelectTwo'
import { useLocation } from 'react-router-dom';
import { Box, FormControl, Input, InputAdornment, IconButton, Button, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TableWrapper from '../../components/table/TableWrapper';
import PaginationWrapper from '../../components/pagination/PaginationWrapper';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FilterListIcon from '@mui/icons-material/FilterList';
import GridComponentBatchClaims from '../../css/GridComponentBatchClaims';
import { Container, Paper, TextField } from '@mui/material';
import { get_GetAllInsuranceDiscoveryData } from '../../services/registration';
import  "../../css/ClaimSearchStyles.css";
import styles from "../../css/importPatient.module.css"
import toast from "react-hot-toast";

const ClaimBatchSearchresultdata = () => {
    const { formWrapper } = styles;
    const pagename = "ClaimBatchSearchresultdata";
    const location = useLocation();
    const searchData = location.state?.searchData || []; 

    return (
        <Box>
    <Box className="marginTopBox"></Box>
    <GridComponentBatchClaims
        pagename={pagename}
        searchData={searchData}
    />
    </Box>
    
    )
}
export default ClaimBatchSearchresultdata