import React from 'react'
import { selectedCardGraphData } from './PatientFunctions'
import SelectedCardChart from '../Dashboard/SelectedCardChart'
import DelayedWrapper from '../../components/DelayedWrapper'
import PatientsOverviewChart from '../Dashboard/PatientsOverviewChart'

const PatientDashboardGraphs = ({ selectedCard, isLoading, patientsOverviewData }) => {
    return (
        selectedCard ?
            <SelectedCardChart data={selectedCardGraphData(selectedCard, patientsOverviewData)} />
            :
            <DelayedWrapper>
                <PatientsOverviewChart isLoading={isLoading}
                    patientsOverviewData={patientsOverviewData}
                />
            </DelayedWrapper>
    )
}

export default PatientDashboardGraphs