import React from 'react'
import ModalWrapper from '../shared/ModalWrapper/ModalWrapper'
import { Box, CircularProgress, FormControl, TextField } from '@mui/material'
import GreenButton from '../claims/GreenButton'
import { sharedStyles } from '../../sharedStyles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const FieldEditModal = ({open,handleClose,fiedlName,action,value,handleChange,isLoading,defaultValue,fieldType,calendar}) => {

  return (
    <ModalWrapper
    open={open}
    handleClose={handleClose}
    >

      {calendar ?
        <FormControl className="formControl" fullWidth sx={sharedStyles?.mv10px}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={fiedlName}
              value={value}
              onChange={handleChange}
            />
          </LocalizationProvider>
        </FormControl>
        :
        <FormControl className="formControl" fullWidth sx={sharedStyles?.mv10px}>
          <TextField
            label={fiedlName}
            name={fiedlName}
            onChange={handleChange}
            required
            value={value}
            type={fieldType}
          />
        </FormControl>}
                <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} sx={sharedStyles?.cursorPointer}>
              <GreenButton variant="contained" onClick={action} disabled={!value || isLoading || String(value) === String(defaultValue)}>
                  {isLoading ?
                      <CircularProgress
                          color="inherit" size={22}
                      /> :
                      'Save'
                  }
              </GreenButton>
      </Box>
    </ModalWrapper>
  )
}

export default FieldEditModal