import { colors } from "../../utils/colors";

export const styles = {
    InnerThemeCardHeader: {
        textAlign: 'center', 
        borderBottom: `1px solid ${colors?.white}`, 
        color: colors?.white, 
        marginBottom: '1em', 
        background: `${colors?.themeGreen}`
    },
}