import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AddNpiId } from '../../services/registration';
import { getLocalData } from '../../utils/localStorageFunctions';
import toast from 'react-hot-toast';
import { styles } from './style';

const ConfirmationModal = ({ handleClose, body, open, data }) => {
    const [isLoading, setIsLoading] = useState(false);
    const userId = getLocalData('user')?.userId;



    const approveEmail = async () => {
        const requestData = { npiId: data?.id, userId: userId }
        setIsLoading(true)
        try {
            const response = await AddNpiId(requestData);
            if (response?.data?.isSuccessful) {
                toast?.success(response?.data?.message);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
            handleClose();
        }
    }


    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby='confirmation-modal' aria-describedby='modal-confirmation-description'>
                <Box sx={styles?.modalWrapper}>
                    <Typography id="modal-modal-title" sx={styles?.modalTitle} variant="h6" component="h2">
                        Do you want to continue?
                    </Typography>
                    <Typography id="modal-modal-description" sx={styles?.modalBody}>
                        {body}
                    </Typography>
                    <Box sx={styles?.buttonWrapper}>
                        <Button disabled={isLoading} variant='contained' onClick={() => {
                            approveEmail();
                        }} color='success'>Yes</Button>
                        <Button variant='contained' disabled={isLoading} onClick={handleClose} color='error'>No</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default ConfirmationModal
