import { Button } from "@mui/material"
import { Link } from "react-router-dom"
import ConfirmationModal from "../../modal/ConfirmationModal";
import { useState } from "react";
import { styles } from "./style";



export const IconCellRendererTwo = ({ data }) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    return (
        <div className="flex">
            <Button variant='contained' color='success' onClick={handleOpen} size='small' sx={styles?.iconCell}>
                Add NPI
            </Button>
            
            <ConfirmationModal
                body={'Your email will be sent to our support team.'}
                handleClose={handleClose}
                handleOpen={handleOpen}
                data={data}
                open={open}
            />
        </div>
    )
}

export const IconCellRenderer = ({ data }) => {
    return (
        <div className="flex">
            <Button variant='contained' color='success' size='small' sx={styles?.iconCell}>
                <Link style={styles?.link} state={{ details: data?.NPI }} to={{
                    pathname: '/npiDetail'
                }}>View Details</Link>
            </Button>
        </div>
    )
};

export const gridOptions = {
    // Enable pagination
    pagination: true,
    // Set the number of rows per page
    paginationPageSize: 10, // Adjust as needed
    // Optional: Specify the layout of pagination components
    domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
    alwaysShowVerticalScroll: true
    // Other grid options...
};