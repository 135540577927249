import React from 'react'
import ModalWrapper from '../shared/ModalWrapper/ModalWrapper'
import { Box, Button, Typography } from '@mui/material';
import { styles } from '../style';
import HorizontalBox from '../shared/HorizontalBox';
import { sharedStyles } from '../../sharedStyles';

const ReusableConfirmationModal = ({ open, onClose, onClick, heading }) => {
    return (
        <ModalWrapper
            open={open}
            handleClose={onClose}
        >
            <Box sx={styles?.modalWrapper}>
                <Typography sx={sharedStyles?.mv10px}>
                    {heading ?? 'Do you want to continue?'}
                </Typography>
                <HorizontalBox sx={sharedStyles?.justifyEnd}>
                    <Button
                        onClick={onClose}
                        sx={sharedStyles?.resetButton}>Cancel</Button>
                    <Button
                        onClick={() => onClick ? onClick() : {}}
                        variant='contained' color='success'>Yes</Button>
                </HorizontalBox>
            </Box>
        </ModalWrapper>
    )
}

export default ReusableConfirmationModal