import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import SeperatedDetails from './SeperatedDetails'
import { claimTabData } from '../../services/ClaimService'

const ClaimTabDetails = ({ data }) => {
    const [claimData, setClaimData] = useState({})
    useEffect(() => {
        claimTabData(data, setClaimData, claimData)
    }, [data])

    return (
        <div>
            <SeperatedDetails array={claimData?.basicDetails} noMt boxSx={{ pb: 1 }} />
            <SeperatedDetails array={claimData?.claimDates} boxSx={{ pb: 1 }} />
            <SeperatedDetails array={claimData?.claimSummary} boxSx={{ borderBottom: '0px' }} />
            <Box sx={{ paddingX: 2, borderRadius: 4, border: '1px solid lightGray', mt: 2 }}>
                <SeperatedDetails array={claimData?.claimDetailDates} />
                <SeperatedDetails array={claimData?.accNumbers} />
                <SeperatedDetails array={claimData?.statusDetails} />
                <SeperatedDetails array={claimData?.categoryDetails} />
                <SeperatedDetails array={claimData?.subscriberDetails} />
                <SeperatedDetails array={claimData?.authorizationDetails} />
                <SeperatedDetails array={claimData?.chargeDetails} boxSx={{ borderBottom: '0px' }} />
                <SeperatedDetails array={claimData?.otherChargesDetails} />
                <SeperatedDetails array={claimData?.denialDetails} boxSx={{ borderBottom: '0px' }} />
            </Box>
        </div>
    )
}

export default ClaimTabDetails