import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement } from 'chart.js';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import {  patientsOverviewGraphEmptyData } from '../../utils/staticData';
import 'chart.js/auto';

const PatientsOverviewChart = ({isLoading, patientsOverviewData}) => { 

    ChartJS.register(LineElement, PointElement);
    // states
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Chart options
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                display: isSmallScreen ? false : true,
                labels: {
                    boxWidth: 10
                }
            }
        }
    };

    return (
        isLoading ? <CircularProgress color="inherit" size={22}/> :
          <Line 
          data={patientsOverviewData?.labels?.length ? patientsOverviewData : patientsOverviewGraphEmptyData} options={options} height={isSmallScreen ? '300px' : '200px'} /> 

    );
};

export default PatientsOverviewChart;
