import { Alert, Box } from '@mui/material'
import React from 'react'
import ErrorIcon from '@mui/icons-material/Error';
const ErrorBoundary = ({ message }) => {
    return (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
            <Alert icon={<ErrorIcon />} sx={{ width: '70%', justifyContent: 'center' }} variant='filled' color='error'>
                {message}
            </Alert>
        </Box>
    )
}

export default ErrorBoundary
