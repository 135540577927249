import React from 'react'
import { styles } from './styles'
import { Typography } from '@mui/material'

const InnerThemeCardHeader = ({heading}) => {
    return (
        <div style={styles?.InnerThemeCardHeader}>
            <Typography variant='h6'>{heading}</Typography>
        </div>
    )
}

export default InnerThemeCardHeader