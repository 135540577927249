import toast from 'react-hot-toast'
import {getDashboardCardDetails, getDiscoveryInsuranceTrends, getInsuranceProvidersDetails, getInsuranceSearchesDetails, getTrendsInInsuranceOverview} from '../../../src/services/registration'
import { colors } from '../../utils/colors'
import { failedGetApi } from '../../utils/reusableFunctions'
import { noDataInGraphStr } from '../../utils/staticData'
import { mdyFormatDate } from '../../utils/DateFunctions'

export const getDashboardCounts = async (setCardsData, setLoading) => {
    try {
        setLoading(true)
        const dashboardData = await getDashboardCardDetails()
        if (dashboardData?.data) {
            const itemToRepeatInList = dashboardData?.data?.find(elem => elem?.type === "PatientsWithoutInsurance")
            setCardsData([...dashboardData?.data, { ...itemToRepeatInList, repeated: true }])
        }
    }
    catch (e) {
        failedGetApi(e)
    }
    finally {
        setLoading(false)
    }
}

export const translateCardType = (val,repeated) => {
    if(val === "PatientsWithActiveCoverage"){
        return "Patients With Active Coverage"
    } 
    else if(val === 'PatientsWithoutInsurance' && !repeated){
        return 'Patients Without Insurance'}
    else if(val === 'PatientsWithoutInsurance' && repeated){
        return 'Patients Without Insurance After Search'}
}

export const getNumberOfInsuranceSearches = async (setData, setLoading) => {
    try {
        setLoading(true)
        const insuranceData = await getInsuranceSearchesDetails()
        if (insuranceData?.count) {
            setData(insuranceData?.count)
        }
        else {
            failedGetApi()
        }
    }
    catch (e) {
        failedGetApi(e)
    }
    finally {
        setLoading(false)
    }
}

export const getInsuranceProviders = async(setData,setLoading) => {
    try{
        setLoading(true)
        const providerData = await getInsuranceProvidersDetails()
    if(providerData?.mostCommonInsurancePayer){
        setData(providerData?.mostCommonInsurancePayer)
    }}
    catch(e){
failedGetApi(e)
    }
    finally{
        setLoading(false)
    }
}

export const insuranceProviderString = (data) => {
  const string =  data?.payerName ? data?.payerName?.concat(':')?.concat(' ')?.concat(data?.count ?? '') : 'N/A' 
  return string
}

export const getInsuranceTrends = async (selectedFilter, setLoading, setTrendsData) => {
    try {
        setLoading(true)
        const [fromDate,toDate] = selectedFilter ? selectedFilter?.split('-') : `${mdyFormatDate(new Date(),true)}-${mdyFormatDate(new Date(),true)}`?.split('-')
        const data = await getDiscoveryInsuranceTrends(selectedFilter ? `fromDate=${fromDate}&toDate=${toDate}` : null)
        if (data?.data) {
            const labels = dataLabels(data?.data)
            const datasets = dataSets(data?.data,labels)
            setTrendsData({ labels, datasets })
        }
        else {
            failedGetApi()
        }
    }
    catch (e) {
        failedGetApi(e)
    }
    finally {
        setLoading(false)
    }
}

const dataSets = (data) => {
    const dataSet = [
                {label: data?.map(elem => elem?.transactionDate?.split(' ')?.[0]),
                data: data?.map(elem => elem?.count),
                borderRadius: 5,
                barThickness: 8,
                backgroundColor: colors?.themeGreen,
            }
            
]
    return dataSet
}

const dataLabels = (data) => {
    if(data?.length){
        const labels = data?.map(elem => elem?.transactionDate?.split(' ')?.[0])
        return labels
    }
    else {
        toast.error(noDataInGraphStr)
        return []}
}

export const options = {
    resposive: true,
    scales: {
        x: {
            grid: {
                display: false
            },
            ticks: {
                display: true
            }
        },
        y: {
            border: { dash: [6, 6] },
            grid: {
                color: colors?.gray,
                offset: true,
                drawOnChartArea: true
            },

            beginAtZero: true
        }
    },
    plugins: {
        legend: {
            display: false
        }
    }
};

export const getTrendsInInsurance = async (selectedFilter, setLoading, setTrendsData) => {
    try {
        setLoading(true)
        // const [fromDate,toDate] = selectedFilter ? selectedFilter?.split('-') : `${mdyFormatDate(new Date(),true)}-${mdyFormatDate(new Date(),true)}`?.split('-')
        const data = await getTrendsInInsuranceOverview()
        if (data?.data) {
            const labels = dataLabels(data?.data)
            const datasets = dataSets(data?.data,labels)
            setTrendsData({ labels, datasets })
        }
        else {
            failedGetApi()
        }
    }
    catch (e) {
        failedGetApi(e)
    }
    finally {
        setLoading(false)
    }
}