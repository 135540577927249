import { Box, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import HorizontalTextPlusHeading from '../../../components/shared/HorizontalTextPlusHeading'
import { styles } from '../styles'
import { claimNumbersDashboardStaticData } from '../../../utils/staticData'

const DashboardGridCard = ({ heading, approvedClaimsData, pendingClaimsData,loader }) => {
    const [gridData,setGridData] = useState(claimNumbersDashboardStaticData)

    useEffect(()=>{
        setGridData([
            { heading: 'Submitted', text: '--' },
            { heading: 'Approved', text: approvedClaimsData ?? '--' , loader:loader?.approvedClaimsLoader},
            { heading: 'Pending', text: pendingClaimsData ?? '--',loader:loader?.pendingClaimsLoader },
            { heading: 'Denied', text: '--' }
        ])
    },[approvedClaimsData,pendingClaimsData,loader])
    
    return (
        <Box>
            <Typography sx={styles.cardText} fontWeight="bold">{heading}</Typography>
            {gridData?.map(({ heading, text,loader }, index) => {
                return (
                    <HorizontalTextPlusHeading
                        key={index}
                        heading={heading}
                        text={loader ? <CircularProgress color='success' size={17}/> : text}
                    />
                )
            })}

        </Box>
    )
}

export default DashboardGridCard