
import React, { useState, useEffect } from 'react';
import { get_GetInusuranceDiscoveryByPatientId, get_GetPatientInsuranceDetailsById, get_GeteligibilitydetailsByrequestId } from '../../services/registration';
import EligibilityDetailtab from '../../components/EligibilityPlandetailtabs/EligibilityDetailtab';
import Eligibilitycoveragetab from '../../components/EligibilityPlandetailtabs/Eligibilitycoveragetab';
import EligibilityDemographic from '../../components/EligibilityPlandetailtabs/EligibilityDemographic';
import EligibilityInnetwork from '../../components/EligibilityPlandetailtabs/EligibilityInnetwork';
import EligibilityOutofnetwork from '../../components/EligibilityPlandetailtabs/EligibilityOutofnetwork';
import EligibilitySpeciality from '../../components/EligibilityPlandetailtabs/EligibilitySpeciality';
import { Box, CircularProgress } from '@mui/material';
import { sharedStyles } from '../../sharedStyles';

const EligibilityPlandetail = ({ tabname, rowData,eligibilityData }) => {
  const [tabdata, setTabdata] = useState([]);
  const [patientInsuranceTabData, setPatientInsuranceTabData] = useState();
  const [isLoading, setIsLoading] = useState(false)

  const formatTransactionDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (rowData && rowData?.ID) {
          let requestID = rowData?.RequestId;
          let PatientId = rowData?.ID;
          const transactionDate = formatTransactionDate(rowData?.TransactionDate);
          const data = await get_GeteligibilitydetailsByrequestId(requestID, PatientId, transactionDate);
          let planSummaryData = [];
          if (data) {
          if (tabname === 'detail') {
            planSummaryData = eligibilityData ? data?.planCoverageSummary : data?.eligibilityDto?.planCoverageSummary;
          } else if (tabname === 'coverage') {
            planSummaryData = eligibilityData ? data?.pcpAuthInfoSummary : data?.eligibilityDto?.pcpAuthInfoSummary;

          } else if (tabname === 'Innetwork' || tabname === 'Outofnetwork' || tabname === 'Speciality' || tabname === 'DemographicInfo') {
            planSummaryData = eligibilityData ? data : data?.eligibilityDto;
          }
        }
          setTabdata([planSummaryData]);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [rowData, tabname]);

  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        setIsLoading(true)
        if (rowData && rowData?.ID) {
          let requestID = rowData?.RequestId;
          let PatientId = rowData?.ID;
          const transactionDate = formatTransactionDate(rowData?.TransactionDate);
          const data = await get_GeteligibilitydetailsByrequestId(requestID, PatientId, transactionDate);
          let planSummaryData = null;
          if (data) {
          if (tabname === 'detail') {
            planSummaryData = data?.patientInsuranceData?.planDetails;
          } else if (tabname === 'coverage') {
            planSummaryData = data?.patientInsuranceData?.coverageDetails;
          } else if (tabname === 'Innetwork' || tabname === 'Outofnetwork' || tabname === 'Speciality' || tabname === 'DemographicInfo') {
            planSummaryData = data?.patientInsuranceData;
          }
        }
          setPatientInsuranceTabData(planSummaryData);
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        console.error('Error fetching data:', error);
      }
    };
    fetchPatientData(); 
  }, []);

  const tabComponents = {
    detail: EligibilityDetailtab,
    coverage: Eligibilitycoveragetab,
    DemographicInfo: EligibilityDemographic,
    Innetwork: EligibilityInnetwork,
    Outofnetwork: EligibilityOutofnetwork,
    Speciality: EligibilitySpeciality
  };
  const TabComponent = tabComponents[tabname];

  return (
    <>

      {
        isLoading ?
          <Box sx={sharedStyles?.horizontalCenteredFlex}>
            <CircularProgress color="success" size={22} />
          </Box> :
          <div>
            {tabdata?.length ? tabdata?.map((item, index) => (
              <div key={index}>
                {TabComponent ? <TabComponent data={item} patientInsuranceTabData={patientInsuranceTabData} tabname={tabname} patientId={rowData?.ID} /> : null}
              </div>
            ))
              : ''
            }

          </div>}
    </>
  );
};

export default EligibilityPlandetail;