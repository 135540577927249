import axios from 'axios';
import toast from "react-hot-toast";

const API_BASE_URL = process.env.REACT_APP_API_URL;
export const get_dentaleligibility = async () => {
    const url = `${API_BASE_URL}/api/pverify/DentalEligibility/GetDentalEligibilitySummary/`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);

    }
};

export const GetDentalEligibilityByPid = async (id) => {
    const url = `${API_BASE_URL}/api/pverify/DentalEligibility/GetDentalEligibilityBenefitSummaryByPatientId/${id}`;
    try {
        const response = await axios.get(url);
        return { data: { ...response.data?.dentalEligibilityRoot, ...response.data?.patientInfoResponse } };

    } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data')
    }
}

export const post_dentaleligibility = async (requestData) => {
    const url = `${API_BASE_URL}/api/DentalEligibility/pverify/PostDentalEligibilityService/`;
    try {
        const response = await axios.post(url, requestData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);

    }
};

export const post_dentaleligibilitybenefit = async (requestData) => {
    const url = `${API_BASE_URL}/api/pverify/DentalEligibility/PostDentalEligibilityBenefitSummary/`;
    try {
        const response = await axios.post(url, requestData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const dentalTabData = (data, setDentalData, dentalData) => {
    const basicDetails = [
        {
            name: 'Patient ID',
            value: data?.id ?? '',
        },
        {
            name: 'Transaction Date',
            value: data?.transactionDate ?? '',
        },
        {
            name: 'Request ID',
            value: data?.requestID ?? '',
        },
        {
            name: 'API Response Code',
            value: data?.apiResponseCode ?? '',
        },
        {
            name: 'API Response Message',
            value: data?.apiResponseMessage ?? '',
        },
        {
            name: 'Payer Name',
            value: data?.payerName ?? '',
        },
        {
            name: 'Verification Type',
            value: data?.verificationType ?? ''
        }
    ];

    const planCoverageDetails = [
        {
            name: 'Status',
            value: data?.planCoverageSummary?.status ?? ''
        },
        {
            name: 'Effective Date',
            value: data?.planCoverageSummary?.effectiveDate ?? ''
        },
        {
            name: 'Expiry Date',
            value: data?.planCoverageSummary?.expiryDate ?? ''
        },
        {
            name: 'Plan Name',
            value: data?.planCoverageSummary?.planName ?? ''
        },
        {
            name: 'Policy Type',
            value: data?.planCoverageSummary?.policyType ?? ''
        },
        {
            name: 'Group Number',
            value: data?.planCoverageSummary?.groupNumber ?? ''
        },
        {
            name: 'Group Name',
            value: data?.planCoverageSummary?.groupName ?? ''
        }
    ];

    const demographicInfo = [
        {
            name: 'Full Name',
            value: data?.demographicInfo?.subscriber?.fullName ?? ''
        },
        {
            name: 'First Name',
            value: data?.demographicInfo?.subscriber?.firstname ?? ''
        },
        {
            name: 'Last Name',
            value: data?.demographicInfo?.subscriber?.lastname_R ?? ''
        },
        {
            name: 'Address',
            value: data?.demographicInfo?.subscriber?.address1 ?? ''
        },
        {
            name: 'City',
            value: data?.demographicInfo?.subscriber?.city ?? ''
        },
        {
            name: 'State',
            value: data?.demographicInfo?.subscriber?.state ?? ''
        },
        {
            name: 'Zip',
            value: data?.demographicInfo?.subscriber?.zip ?? ''
        },
        {
            name: 'DOB',
            value: data?.demographicInfo?.subscriber?.doB_R ?? ''
        },
        {
            name: 'Gender',
            value: data?.demographicInfo?.subscriber?.gender_R ?? ''
        },
        {
            name: 'Member ID',
            value: data?.demographicInfo?.subscriber?.identification?.find(elem => elem?.type === 'Member ID')?.code ?? ''
        },
        {
            name: 'Group Number',
            value: data?.demographicInfo?.subscriber?.identification?.find(elem => elem?.type === 'Group Number')?.code ?? ''
        },
        {
            name: 'Group Name',
            value: data?.demographicInfo?.subscriber?.identification?.find(elem => elem?.type === 'Group Name')?.code ?? ''
        }
    ];

    const benefitDetails = data?.benefitDetails?.map(benefit => ({
        name: benefit?.benefitName ?? '',        
        value: {
            monetoryamount: benefit?.eligibilityDetails?.[0]?.monetaryAmount ?? '',
            planCoverageDescription: benefit?.eligibilityDetails?.[0]?.planCoverageDescription ?? '',
            monetoryamount1: benefit?.eligibilityDetails?.[1]?.monetaryAmount ?? '',
            planCoverageDescription1: benefit?.eligibilityDetails?.[1]?.planCoverageDescription ?? ''
        }
    })) ?? [];

    setDentalData({
        ...dentalData,
        basicDetails,
        planCoverageDetails,
        demographicInfo,
         benefitDetails
    });
};