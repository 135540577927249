import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import DentalSeperatedDetails from "./DentalSeperatedDetails";
import { dentalTabData } from "../../services/DentalEligibilityService";
import styles from "../../css/DentalSeperatedDetails.module.css";

const DentalTabDetails = ({ data, tabnum }) => {
  const [dentalData, setDentalData] = useState({});

  useEffect(() => {
    dentalTabData(data, setDentalData);
  }, [data]);

  return (
    <div>
      {tabnum === "1" && dentalData?.planCoverageDetails && (
        <DentalSeperatedDetails
          array={dentalData?.planCoverageDetails}
          boxSx={styles.paddingBottom1}
        />
      )}
      {tabnum === "2" && dentalData?.basicDetails && (
        <DentalSeperatedDetails
          array={dentalData?.basicDetails}
          classname="noMarginTop"
          boxSx={styles.paddingBottom1}
        />
      )}
      {tabnum === "3" && dentalData?.demographicInfo && (
        <DentalSeperatedDetails
          array={dentalData?.demographicInfo}
          classname="noMarginTop"
          boxSx={styles.paddingBottom1}
        />
      )}
      {tabnum === "4" && dentalData?.benefitDetails && (
        <Box className={styles.dentalBox}>
          <DentalSeperatedDetails
            array={[dentalData?.benefitDetails[2]]}
            boxSx={styles.borderBottomZero}
          />
        </Box>
      )}
    </div>
  );
};

export default DentalTabDetails;
