import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { patientsClaimsHeadings } from '../../utils/staticData'
import CircleWrapper from '../../components/shared/CircleWrapper'
import { TbDiscount } from 'react-icons/tb'
import { FaDollarSign } from 'react-icons/fa'
import { colors } from '../../utils/colors'
import { styles } from './styles'
import { useNavigate } from "react-router-dom";

const PatientClaimsInsightCards = () => {
    const navigate = useNavigate()
    return (
        <Grid container  >
            {patientsClaimsHeadings?.map((elem, index) => {
                return (
                    <Grid item xs={12} sm={6}
                        key={index} paddingLeft={2}
                        marginY={2}
                    >
                        <Box boxShadow={3} paddingY={2} paddingX={2} borderRadius={4}
                            // onClick={() => navigate('/ClaimTab')}
                            sx={styles.card}
                        >
                            <CircleWrapper >
                                {index % 2 === 0 ? <TbDiscount stroke={colors?.themeGreen} /> : <FaDollarSign fill={colors?.themeGreen} />}
                            </CircleWrapper>
                            <Typography variant="body1" sx={styles.cardText}>
                                {elem}
                            </Typography>
                            <Typography variant="body1" sx={styles.greenCardValue}>
                                0
                            </Typography>
                        </Box>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default PatientClaimsInsightCards