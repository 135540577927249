import React from 'react';
import { Box } from '@mui/material';
import MyTabs from '../../components/tabs/MyTabs';
import DentalSearchresultdata from '../Eligibility/DentalSearchresultdata';
import styles from '../../css/Dentaldetails1.module.css';

const DentalSearchresults = () => {
    const tabs = [
        {
            label: "Results",
            value: <DentalSearchresultdata />,
            changeTab: 0,
            button: 'Back',
            backPath:'/EligibilityTab?preSelectedTab=2'
        },
    ];

    return (
        <section className={styles.eligibilitySection}>
            <Box className={styles.boxShadow}>
                <MyTabs tabs={tabs} />
            </Box>
        </section>
    );
};

export default DentalSearchresults;
