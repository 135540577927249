import React from 'react'
import HorizontalBox from '../../components/shared/HorizontalBox'
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { sharedStyles } from '../../sharedStyles';
import { colors } from '../../utils/colors';

const PatientOverviewEasyFilter = ({date,leftAction,rightAction,disableRightAction,onClick}) => {
    return (
        <HorizontalBox sx={{...sharedStyles?.justifyBetween,...sharedStyles?.gap2}}
        >
            <FaChevronCircleLeft style={sharedStyles?.cursorPointerOnly} color={colors?.themeGreen} 
            onClick={leftAction}
            />
            <p style={sharedStyles?.cursorPointerOnly}
            onClick={onClick}
            >{date}</p>
            <FaChevronCircleRight 
            style={!disableRightAction && sharedStyles?.cursorPointerOnly} 
            color={disableRightAction ? colors?.gray : colors?.themeGreen}
            onClick={() => !disableRightAction && rightAction()}

            />

        </HorizontalBox>
    )
}

export default PatientOverviewEasyFilter