import './App.css';
import './css/Header.css';
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import '@aws-amplify/ui-react/styles.css';
import Layout from './Layout/Layout';
import { useAuth0 } from '@auth0/auth0-react';
import { Toaster } from 'react-hot-toast';
import PatientDetail from './Pages/Patients/PatientDetail';
import EditViewProfile from './Pages/Profile/EditViewProfile';
import Profile from './Pages/Profile/Profile';
import NpiDetail from './Pages/Profile/NpiDetail';
import DentalSearch from './Pages/Eligibility/DentalSearch';
import Dentaldetails1 from './Pages/Eligibility/Dentaldetails1';
import ClaimBatchSearchresultdata from './Pages/Claims/ClaimBatchSearchresultdata';
import ImportClaims from './Pages/Claims/ImportClaims';
import BatchClaimResult from './Pages/Claims/BatchClaimResponse';
import PayerDetail from './Pages/Profile/PayerDetail';
import Notification from './Pages/Notifications/Notification';
import Appointments from './Pages/Appointments/Appointments'
import AllRoutes from './Routes';

function App() {
  
  const { isAuthenticated, loginWithRedirect, getIdTokenClaims, isLoading, logout, } = useAuth0();

  const checkAuthentication = async () => {
    if (!isLoading) {
      if (!isAuthenticated) {
        loginWithRedirect();
      }

    }
  };
  useEffect(() => {
    checkAuthentication();
  }, [isLoading, isAuthenticated, logout, getIdTokenClaims, loginWithRedirect]);

  return (
    <>
      <Router>
        {
          <Routes>
            <Route
              path='/*'
              element={
                isAuthenticated ? (
                  <Layout>
                    <AllRoutes/>
                    <Toaster />
                  </Layout>
                ) : (
                  null
                )
              }
            />
          </Routes>
        }
      </Router>
    </>
  );
}
export default App;