import { Box, Grid } from '@mui/material'
import React from 'react'
import CircleWrapper from '../../../components/shared/CircleWrapper'
import { styles } from '../styles'
import { sharedStyles } from '../../../sharedStyles'

const DashboardGridItemContainer = ({icon,children}) => {
  return (
    <Grid item xs={12} md={4} sx={sharedStyles?.displayFlex}>
    <Box
        sx={styles?.dashboardItemContainer}
    >
        <CircleWrapper>
            {icon}
        </CircleWrapper>
        <Box>
          {children}
        </Box>
    </Box>
</Grid>
  )
}

export default DashboardGridItemContainer