import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const PageLoader = ({height}) => {
    return (
        <Box sx={{
            height: height ?? '50vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <CircularProgress color='success' />
        </Box>
    )
}

export default PageLoader
