import { createContext, useState } from "react";
import { staticNotifications } from "../utils/staticData";

const ContextProvider = createContext();


const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState(staticNotifications);

    const newNotification = (newNot) => {
        setNotifications((prev) => {
            return [...prev, newNot]
        })
    }

    return (
        <ContextProvider.Provider value={{ notifications, newNotification }}>
            {children}
        </ContextProvider.Provider>
    )
};


export { NotificationProvider, ContextProvider };