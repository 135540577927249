import React, { useEffect, useState } from 'react';
import HorizontalHeadingPlusText from '../shared/HorizontalHeadingPlusText';
import { update_UpdatePatientInsuranceDetails } from '../../services/registration';
import { Button, Grid, Paper, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import styles from "../../css/discoveryDetails.module.css";
import { formatInNetworkDetails, getTabNameLabel } from '../../Pages/Discovery/DiscoveryFunctions';
import SyncButton from './SyncButton';

const Innetwork = ({ data, patientInsuranceTabData, tabname, patientId }) => {
  const { leftBox, header, rightBox, button, buttonGrid } = styles; 
  const inNetworkDetails = [
    { name: 'Individual Deductible In-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualDeductibleInNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualDeductibleInNet || 'N/A' },
    { name: 'Individual Deductible Remaining In-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingInNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingInNet || 'N/A' },
    { name: 'Family Deductible In-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyDeductibleInNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyDeductibleInNet || 'N/A' },
    { name: 'Family Deductible Remaining In-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingInNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyDeductibleRemainingInNet || 'N/A' },
    { name: 'Individual OOP In-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualOOP_InNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualOOP_InNet || 'N/A' },
    { name: 'Individual OOP Remaining In-Net', value: data?.hbpC_Deductible_OOP_Summary?.individualOOPRemainingInNet?.value || data?.hbpC_Deductible_OOP_Summary?.individualOOPRemainingInNet || 'N/A' },
    { name: 'Family OOP In-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyOOPInNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyOOPInNet || 'N/A' },
    { name: 'Family OOP Remaining In-Net', value: data?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingInNet?.value || data?.hbpC_Deductible_OOP_Summary?.familyOOPRemainingInNet || 'N/A' }
  ];

  const [patientInsuranceDetailTabData, setPatientInsuranceDetailTabData] = useState([]); 
  const [isLoading, setIsLoading] = useState(false)

  useEffect(()=>{
    setPatientInsuranceDetailTabData(formatInNetworkDetails(patientInsuranceTabData))
  },[patientInsuranceTabData])

  const handleSavePatientsDetails = async (tabName, patientId) => {
    try {
      setIsLoading(true)
      const patientUpdatedData = await update_UpdatePatientInsuranceDetails(tabName, patientId);
      if (patientUpdatedData?.patientData) {
        setPatientInsuranceDetailTabData(formatInNetworkDetails(patientUpdatedData?.patientData?.inNetworkDetails));
      } else {
        toast('Innetwork detail is empty');
      }
    } catch (error) {
      console.error(error);
    }
    finally{
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={leftBox}>
            <Typography variant="h5" align="center" className={header}>
              Payer
            </Typography>
            <Typography align="center" variant="h5" className={header}>
            {getTabNameLabel(tabname)}
            </Typography>
            <hr/>
            {inNetworkDetails?.length && inNetworkDetails?.map((detail, index) => (
              <HorizontalHeadingPlusText heading={detail?.name} text={detail?.value} key={index} />
            ))}
          </Paper>
        </Grid>

        {/* Button */}
        <Grid item xs={12} md={2} className={buttonGrid}>
          <SyncButton onClick={()=>handleSavePatientsDetails(tabname, patientId)} isLoading={isLoading}/> 
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper elevation={10} className={rightBox}>
            <Typography variant="h5" align="center" className={header}>
              Practice
            </Typography>
            <Typography align="center" variant="h5" className={header}>
            {getTabNameLabel(tabname)}
            </Typography>
            <hr />
            {
              patientInsuranceDetailTabData?.length && patientInsuranceDetailTabData?.map(({ heading, text }, index) => {
                return (
                  <HorizontalHeadingPlusText heading={heading} text={text || 'N/A'} key={index} />
                )
              })
            }
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Innetwork;
